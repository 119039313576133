// components
import { DataTableRow, Tag } from "@flixbus/honeycomb-react";
import { DateAndTime, ValueWithCoping } from "../../../../components";
// constants
import { TIMEZONES } from "../../../../constants";

export const PromoCodeTableRow = ({ voucher, key }) => {
	return voucher ? (
		<DataTableRow key={key}>
			<td>
				<ValueWithCoping dispayFullValue={true} value={voucher.code} />
			</td>
			<td>{voucher.prefix}</td>
			<td>{voucher.usageType}</td>
			<td>{voucher.discountType}</td>
			<td>{voucher.revenueType}</td>
			<td>{voucher.status}</td>
			<td>{voucher.discount}</td>
			<td>{voucher.discountOrigin}</td>
			<td>{voucher.credit}</td>
			<td>{voucher.creditOrigin}</td>
			<td>
				<Tag>{voucher.promocodeType}</Tag>
			</td>
			<td>
				<DateAndTime
					timeStamp={voucher.createdAt}
					isDepartureType={false}
					timeZone={TIMEZONES.CET}
				/>
			</td>
			<td className="VoucherSpecification">{voucher.specification}</td>
		</DataTableRow>
	) : null;
};
