import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@flixbus/honeycomb-react";
import { authenticate } from "../utils";

/* button which will open a popup for microsoft login */
export const SignInButton = () => {
	const { instance } = useMsal();

	return (
		<Button
			extraClasses="SignInBtn"
			appearance="primary"
			title="Sign in"
			onClick={() => authenticate(instance)}
		>
			Sign in
		</Button>
	);
};
