/* component inside a popup with comments for a selected order item */
import React, { useState, useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { authenticate, isLocalhost } from "../../utils";
import { addComment } from "../../api";
/* constants */
import { DefaultErrorMessage, TestUser } from "../../constants";
/* HC components */
import {
	Heading,
	NavHorizontal,
	NavItem,
	Popup,
	PopupSection,
	Textarea,
	Button,
	NotificationContainer,
	Notification,
	Spinner,
} from "@flixbus/honeycomb-react";
/* components */
import { CommentsHistory } from "./CommentsHistory/CommentsHistory";
/* icons */
import { IconEdit, IconDocument } from "@flixbus/honeycomb-icons-react";
/* styles */
import "./CommentsPopup.scss";
import { editComment } from "../../api/Comments";
import AccessGuard from "../../auth/AccessGuard";
import { USER_ROLES } from "../../auth/UserRoles";

export const CommentsPopup = ({
	isViewHistory = false,
	selectedItem,
	onClose,
}) => {
	const [isCommentHistoryShown, setIsCommentHistoryShown] =
		useState(isViewHistory);
	const [newComment, setNewComment] = useState("");
	const isAuthenticated = useIsAuthenticated();
	const { instance, accounts } = useMsal();
	const request = {
		...loginRequest,
		account: accounts[0],
	};
	const [token, setToken] = useState(null);
	const [alertMessage, setAllertMessage] = useState("");
	const [showNotification, setShowNotification] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [user, setUser] = useState(null);

	useEffect(() => {
		(async () => {
			if (isAuthenticated && !!accounts.length) {
				// get user info
				const currentUser = {
					username: accounts[0].name,
					email: accounts[0].username,
					jobTitle: accounts[0].idTokenClaims.roles[0],
				};
				setUser(currentUser);
				// get token
				await instance
					.acquireTokenSilent(request)
					.then((response) => {
						if (response) {
							setToken(response.idToken);
						} else {
							console.log("no token");
						}
					})
					.catch((e) => {
						console.error(e);
						localStorage.clear();
						// redirect to login
						instance.loginRedirect(loginRequest);
					});
			} else {
				if (isLocalhost()) {
					// test user
					setUser(TestUser);
					// for local env
					console.log("Not authenticated on local host");
				} else {
					authenticate(instance);
				}
			}
		})();
	}, []);

	useEffect(() => {
		setIsCommentHistoryShown(isViewHistory);
	}, [isViewHistory]);

	const handleCommentChange = (e) => {
		setNewComment(e.target.value);
	};

	const handleCommentSave = () => {
		accounts.forEach((account) => {
			console.log(account);
		});

		const newCommentBody = {
			comment: newComment,
			userInfo: user,
		};
		// for local env
		if (isLocalhost()) {
			setHasError(false);
			setAllertMessage("Test success");
			setShowNotification(true);
			setNewComment("");
			setTimeout(() => {
				onClose(true);
			}, 2000);
		}
		// save comment
		if (token && newCommentBody && selectedItem.id) {
			addComment(token, newCommentBody, selectedItem.id)
				.then((response) => {
					// when comment is successfully added
					if (response.status == 200) {
						setNewComment("");
						setHasError(false);
						setAllertMessage("Comment added successfully");
						setShowNotification(true);
						// close popup after 2 seconds
						setTimeout(() => {
							onClose(true);
						}, 2000);
					} else {
						// comment was not added
						let errorMessage = `${response.message || DefaultErrorMessage}`;
						setAllertMessage(errorMessage);
						setHasError(true);
						setShowNotification(true);
					}
				})
				.catch((e) => {
					console.error(e);
					setHasError(true);
					setAllertMessage(e);
					setShowNotification(true);
				});
		}
	};

	const handleCommentEdit = (comment, commentId) => {
		// for local env
		if (isLocalhost()) {
			setIsLoading(true);
			setHasError(false);
			setAllertMessage("Test edit success");
			setShowNotification(true);
			setTimeout(() => {
				setIsLoading(false);
				onClose(true);
			}, 2000);
			return;
		}
		if (token && commentId && selectedItem.id) {
			setIsLoading(true);
			// API call to edit comment
			editComment(token, selectedItem.id, commentId, comment, user)
				.then((response) => {
					// when comment is successfully updated
					if (response.status == 200) {
						setHasError(false);
						setAllertMessage("Comment is successfully edited");
						setShowNotification(true);
						// show comment history tab
						setIsCommentHistoryShown(true);
						setIsLoading(false);
						// close popup after 2 seconds
						setTimeout(() => {
							onClose(true);
						}, 1000);
					} else {
						// if comment was not updated
						let errorMessage = `${response.message || DefaultErrorMessage}`;
						setAllertMessage(errorMessage);
						setHasError(true);
						setShowNotification(true);
						setIsLoading(false);
					}
				})
				.catch((e) => {
					console.error(e);
					setHasError(true);
					setAllertMessage(e);
					setShowNotification(true);
					setIsLoading(false);
				});
		} else {
			console.error("No token or comment id");
			// show notification
			setHasError(true);
			setAllertMessage(DefaultErrorMessage);
			setShowNotification(true);
			setIsLoading(false);
		}
	};

	const handleCommentDelete = (commentId) => {
		// TODO: implement delete comment
		console.log("delete comment", commentId);
		// show warning notification
		setHasError(true);
		setAllertMessage("Deletion of comments is not implemented yet");
		setShowNotification(true);
	};

	const handleClose = () => {
		setNewComment("");
		onClose();
	};

	return (
		<>
			<Popup
				aria-labelledby="comments-popup-heading"
				active={true}
				onOverlayClick={handleClose}
				data-testid="comments-popup"
			>
				<PopupSection type="title">
					<Heading
						id="comments-popup-heading"
						data-testid="comments-popup-heading"
						size={2}
						Elem="h3"
					>
						Comments for <span>#{selectedItem.id}</span>
					</Heading>

					{/* tabs to switch */}
					<NavHorizontal aria-label="Shortcuts" extraClasses="test">
						<NavItem
							InlineIcon={IconDocument}
							href=""
							active={isCommentHistoryShown}
							onClick={() => setIsCommentHistoryShown(true)}
							data-testid="comments-popup-comments-history-tab-button"
						>
							Comments History
						</NavItem>
						<AccessGuard
							allowedAzureGroups={[USER_ROLES.SUPER_USER, USER_ROLES.USER]}
							showMessage={false}
						>
							<NavItem
								InlineIcon={IconEdit}
								href=""
								active={!isCommentHistoryShown}
								onClick={() => setIsCommentHistoryShown(false)}
								data-testid="comments-popup-add-new-comment-tab-button"
							>
								Add New Comment
							</NavItem>
						</AccessGuard>
					</NavHorizontal>
				</PopupSection>

				<PopupSection type="content">
					{/* show loader when loading comments */}
					{isLoading ? (
						<div
							className="d-flex justify-content-center py-4"
							aria-live="polite"
							aria-busy={isLoading}
						>
							<Spinner />
						</div>
					) : isCommentHistoryShown ? (
						<CommentsHistory
							comments={selectedItem.comments}
							onCommentEdit={(comment, commentId) =>
								handleCommentEdit(comment, commentId)
							}
							onCommentDelete={(commentId) => handleCommentDelete(commentId)}
						/>
					) : (
						// form to add new comment
						<Textarea
							name="comment"
							placeholder="Write new comment"
							id="comment"
							label="Your comment"
							onChange={handleCommentChange}
							value={newComment}
							autoFocus
							data-testid="comments-popup-add-new-comment-textarea"
						/>
					)}
				</PopupSection>

				<PopupSection type="actions">
					{/* close button is always visible for the popup */}
					<Button
						id="comments-popup-close"
						appearance={isCommentHistoryShown ? "primary" : "tertiary"}
						onClick={handleClose}
					>
						{isCommentHistoryShown ? "Close" : "Cancel"}
					</Button>
					{/* save button is only visible for the add new comment form */}
					{!isCommentHistoryShown && (
						<Button
							appearance="primary"
							onClick={handleCommentSave}
							data-testid="comments-popup-add-new-comment-submit-button"
						>
							Save
						</Button>
					)}
				</PopupSection>
			</Popup>
			{/* Notifications */}
			{showNotification && (
				<NotificationContainer>
					<Notification
						toast
						appearance={hasError ? "danger" : "success"}
						dismissCallback={() => setShowNotification(false)}
						closeProps={{
							"aria-label": "Close",
							onClick: () => setShowNotification(false),
						}}
					>
						{alertMessage}
					</Notification>
				</NotificationContainer>
			)}
		</>
	);
};
