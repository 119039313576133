//components
import { Button, ButtonGroup } from "@flixbus/honeycomb-react";
// Constants
import { MMB_ENDPOINT } from "../../../../constants";
// icons
import {
	Icon,
	IconEdit,
	IconTicket,
	IconInvoice,
} from "@flixbus/honeycomb-icons-react";

export const OrderActionButtons = ({ order }) => (
	<ButtonGroup>
		{order.ticketUrl ? (
			<Button
				appearance="secondary"
				Elem="a"
				target="_blank"
				href={order.ticketUrl}
				data-testid="ticket-pdf-button"
			>
				<Icon InlineIcon={IconTicket} />
				Ticket PDF
			</Button>
		) : null}
		{order.invoiceUrl ? (
			<Button
				appearance="secondary"
				Elem="a"
				target="_blank"
				href={order.invoiceUrl}
				data-testid="invoice-pdf-button"
			>
				<Icon InlineIcon={IconInvoice} />
				Invoice PDF
			</Button>
		) : null}
		<Button
			appearance="secondary"
			Elem="a"
			target="_blank"
			href={`${MMB_ENDPOINT}?auth_order=${order.id}&auth_token=${order.token}`}
			data-testid="change-order-button"
		>
			<Icon InlineIcon={IconEdit} />
			Change Order
		</Button>
	</ButtonGroup>
);
