export const buildRefundCommentBody = (refundReason, user) => {
	const RefundAutoComment = `Order item was requested to be refunded via payment provider. Refund Reason: ${
		refundReason || "Unknown"
	}`;
	const currentUser = {
		username: user?.userPrincipal || "BV User",
		email: user?.email || "N/A",
		jobTitle: user?.roles[0] || "N/A",
	};
	// obj for comments API
	return {
		comment: RefundAutoComment,
		userInfo: currentUser,
	};
};
