import React from "react";
import { useNavigate } from "react-router-dom";
import {
	Button,
	Grid,
	GridCol,
	PageContainer,
	Text,
} from "@flixbus/honeycomb-react";
// reuse styles from NotFound
import "../notFound/NotFound.scss";

const NotAllowed = () => {
	const navigate = useNavigate();

	const handleButtonClick = () => {
		navigate("/");
	};

	return (
		<PageContainer>
			<Grid align="center">
				<GridCol key="grid-btm-a" size={12}>
					<div className="NotFoundContainer">
						<h1>Access Restricted</h1>
						<Text extraClasses="Message">
							You don't have access to this page. <br /> Please contact your
							manager to request the necessary permissions.
						</Text>
						<br />
						<flix-illustration name="maintenance"></flix-illustration>
						<br />
						<Button appearance="primary" onClick={handleButtonClick}>
							Return to Home Page
						</Button>
					</div>
				</GridCol>
			</Grid>
		</PageContainer>
	);
};

export default NotAllowed;
