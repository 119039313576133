export const updateLineNumberInput = (
	lineList,
	Filters,
	setFieldValue,
	handleChange
) => {
	// when line is not found in the autocomplete list
	if (!lineList || !lineList.length) {
		setFieldValue(Filters.lineIds, []);
		setFieldValue(Filters.lineTitle, "");
		handleChange(Filters.lineTitle);
	}
};
