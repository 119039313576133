// price value as a float number following a currency

export const Price = ({ value, currency = "" }) => {
	if (value === null || value === undefined) {
		return <span>N/A</span>;
	}
	const priceValue = parseFloat(value);

	return <span>{priceValue.toFixed(2) + " " + currency}</span>;
};
