import { useEffect, useState } from "react";

import { Grid, GridCol } from "@flixbus/honeycomb-react";
import "./RefundAmountError.scss";

export const RefundAmountError = ({
	isCustomRefund,
	itemsAmount,
	maxRefundAmount,
	isValidItemsAmount,
	isValidCustomAmount,
	customAmount,
}) => {
	const [errorMessage, setErrorMessage] = useState(null);

	useEffect(() => {
		if (isCustomRefund) {
			if (customAmount <= 0 && !isValidCustomAmount) {
				setErrorMessage("Please enter custom refund amount");
			} else {
				setErrorMessage(null);
			}
		} else {
			if (itemsAmount > maxRefundAmount) {
				setErrorMessage(
					"Amount to Refund exceeds the Maximum Refundable Amount"
				);
			} else if (!isValidItemsAmount) {
				setErrorMessage("Please select items for refund");
			} else {
				setErrorMessage(null);
			}
		}
	}, [
		isCustomRefund,
		itemsAmount,
		maxRefundAmount,
		isValidCustomAmount,
		isValidItemsAmount,
		customAmount,
	]);

	return (
		<Grid>
			<GridCol
				size={3}
				push={3}
				extraClasses={errorMessage ? "ErrorValidationMessage" : "hidden"}
			>
				<span>{errorMessage}</span>
			</GridCol>
		</Grid>
	);
};
