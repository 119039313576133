// component to dispay the phone number of the passenger, takes the phone number from the orderItems if it is not passed as a prop
import React, { useEffect, useState } from "react";
import "./Phone.scss";
import { anonymizePersonalData } from "../../utils/anonymizePersonalData";

export const Phone = ({ phoneNumber = null, orderItems = null }) => {
	const [passengerPhone, setPassengerPhone] = useState("");

	// Function to format phone number in a readable format
	const formatPhoneNumber = (phoneNumber) => {
		if (!phoneNumber) return null;
		// TODO: Format the phone number using regular expressions
		// anonymize the phone number if it is dev environment
		if (!+process.env.REACT_APP_IS_PRODUCTION) {
			return anonymizePersonalData(phoneNumber, "phone");
		}
		// Return the original phone number if it shoudn't be formatted
		return phoneNumber;
	};

	useEffect(() => {
		// Format the phone number and display it
		if (phoneNumber) {
			const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
			setPassengerPhone(formattedPhoneNumber);
		}
		// if phone is not passed, try to get it from orderItems
		if (orderItems && orderItems.length) {
			let orderItemPhone = null;
			// get the first available phone from passenger, because phone is the same for all passengers inside an order
			orderItems.some((orderItem) => {
				if (orderItem.passenger?.phone) {
					orderItemPhone = orderItem.passenger.phone;
					return true;
				}
			});
			if (!+process.env.REACT_APP_IS_PRODUCTION) {
				setPassengerPhone(formatPhoneNumber(orderItemPhone));
			} else {
				setPassengerPhone(orderItemPhone);
			}
		}
	}, [phoneNumber, orderItems]);

	return passengerPhone ? (
		<div className="PhoneLine">{passengerPhone}</div>
	) : (
		"N/A"
	);
};
