import { DataTable } from "@flixbus/honeycomb-react";
import { RidesTableHeaders } from "../../../../constants";
import { RidesTableRow } from "..";

export const RidesTable = ({ rides, pagination }) => {
	return rides && rides.length ? (
		<>
			<DataTable headers={RidesTableHeaders} scrollable controls={pagination}>
				{rides.map((ride, i) => (
					<RidesTableRow key={i} ride={ride} />
				))}
			</DataTable>
		</>
	) : null;
};
