import React from "react";
import Moment from "moment";
import { DateShortTime, DateShortYear, DateFullTime } from "../../constants";
import {
	Icon,
	IconBusTime,
	IconCalendar,
	IconTime,
} from "@flixbus/honeycomb-icons-react";
import "./DateAndTime.scss";

// when it's a departure type, it will show the bus icon, otherwise the clock icon and the full time with seconds
export const DateAndTime = ({
	timeStamp,
	isDepartureType = true,
	timeZone = "",
}) => {
	return (
		<div>
			{/* date */}
			{timeStamp ? (
				<div className="DateLine">
					<Icon
						InlineIcon={IconCalendar}
						size={4}
						extraClasses="DateLine__icon"
					/>
					{Moment(timeStamp).format(DateShortYear)}
				</div>
			) : (
				"N/A"
			)}
			{/* time */}
			{timeStamp ? (
				<div className="DateLine">
					<Icon
						InlineIcon={isDepartureType ? IconBusTime : IconTime}
						size={4}
						extraClasses="DateLine__icon"
					/>
					<span>
						{Moment(timeStamp).format(
							isDepartureType ? DateShortTime : DateFullTime
						)}{" "}
						{timeZone}
					</span>
				</div>
			) : null}
		</div>
	);
};
