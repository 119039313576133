import { Tooltip } from "@flixbus/honeycomb-react";
import "./RoutesList.scss";
import { useEffect, useState } from "react";

export const RoutesList = ({ orderItems }) => {
	const [orderItemsWithUniqueRoutes, setOrderItemsWithUniqueRoutes] = useState(
		[]
	);

	useEffect(() => {
		if (!orderItems || !orderItems.length) {
			return <span>N/A</span>;
		}
		let routeNames = [];
		let uniqueRoutes = [];
		// filter unique routes
		orderItems.forEach((orderItem) => {
			let route = orderItem.route;
			if (!!route && !routeNames.includes(route)) {
				routeNames.push(route);
				uniqueRoutes.push(orderItem);
			}
		});
		setOrderItemsWithUniqueRoutes(uniqueRoutes);
	}, [orderItems]);

	return (
		<>
			{orderItemsWithUniqueRoutes.length
				? orderItemsWithUniqueRoutes.map((o) => {
						return (
							<div key={`route-info-${o.id}`}>
								{/* if full or short route name  is available - show tooltip */}
								{o.fullRouteName || o.route ? (
									<Tooltip
										content={o.fullRouteName || o.route}
										data-testid={`route-tooltip-${o.id}`}
										id={`route-tooltip-${o.id}`}
										position="bottom"
										openOnHover
									>
										<span
											data-testid={`route-name-${o.id}`}
											className="ShortRouteName"
										>
											{o.route || o.fullRouteName || "N/A"}
										</span>
									</Tooltip>
								) : (
									<span data-testid={`empty-route-name-${o.id}`}>No info</span>
								)}
							</div>
						);
				  })
				: "N/A"}
		</>
	);
};
