import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect } from "react";
import { isLocalhost } from "../utils";
import NotAllowed from "../routes/notAllowed/NotAllowed";

const AccessGuard = (props) => {
	const { instance, accounts } = useMsal();
	const { children, allowedAzureGroups, showMessage = false } = props;
	const [authorized, setAuthorized] = useState(null);

	const checkRoles = () => {
		const currentAccount = accounts[0] || instance.getActiveAccount();
		if (isLocalhost()) {
			// if the app is running on localhost, allow access as for admin user
			setAuthorized(true);
			return;
		}
		if (currentAccount && currentAccount.idTokenClaims.roles) {
			const UserRoles = currentAccount.idTokenClaims.roles;
			// loop through the allowed groups and check if the user is a member of any of them
			let isAllowed = allowedAzureGroups.some((group) => {
				return UserRoles.includes(group);
			});
			setAuthorized(isAllowed);
		} else {
			setAuthorized(false);
		}
	};

	useEffect(() => {
		checkRoles();
	}, [instance]);

	// hide features from unauthorized users, if required - show not allowed page
	return (
		<>
			{authorized !== null ? (
				<>
					{authorized ? <>{children}</> : showMessage ? <NotAllowed /> : null}
				</>
			) : null}
		</>
	);
};

export default AccessGuard;
