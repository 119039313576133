/* Pagination component: current page is placed in the center of the window size 5, two more pages are shown before and after the current page. When there are many pages dots after the 1st page and before the last page are shown */
import { Pager, PagerArrow, PagerItem } from "@flixbus/honeycomb-react";
import { useState, useEffect } from "react";
import { range } from "../../utils";

export const Pagination = ({ onPageChange, pagesAmount, currentPage }) => {
	const PaginationPagesToShow = 5;
	const pagesToShowNearCurrentPage = 2;
	const pagesToShow =
		pagesAmount > PaginationPagesToShow ? PaginationPagesToShow : pagesAmount;

	const [isFurtherDotsShown, setIsFurtherDotsShown] = useState(false);
	const [isPrevDotsShown, setIsPrevDotsShown] = useState(false);
	const [startRangeFrom, setStartRangeFrom] = useState(1);
	const [pagesArray, setPagesArray] = useState(range(pagesToShow, 1));

	const handleNextClick = () => {
		if (currentPage < pagesAmount) {
			onPageChange(currentPage + 1);
		}
	};

	const handlePrevClick = () => {
		if (currentPage > 1) {
			onPageChange(currentPage - 1);
		}
	};

	useEffect(() => {
		// dots before the last page
		let showNextDots =
			pagesAmount - currentPage > pagesToShowNearCurrentPage + 1 &&
			pagesAmount > pagesToShow;
		setIsFurtherDotsShown(showNextDots);
		// dots after the 1st page
		let showPrevDots =
			currentPage - 1 > pagesToShowNearCurrentPage + 1 &&
			pagesAmount > pagesToShow;
		setIsPrevDotsShown(showPrevDots);
		// calculate pages window
		let startFrom = 1;
		if (
			currentPage === 1 ||
			currentPage < pagesToShowNearCurrentPage + 1 ||
			pagesAmount <= pagesToShow
		) {
			startFrom = 1;
		} else if (currentPage > 1 && currentPage < pagesToShow) {
			startFrom = 2;
		} else if (
			currentPage > 1 &&
			currentPage >= pagesToShow &&
			currentPage < pagesAmount - 1
		) {
			startFrom = currentPage - pagesToShowNearCurrentPage;
		} else {
			startFrom = pagesAmount - pagesToShow + 1;
		}
		setStartRangeFrom(startFrom);
	}, [pagesAmount, currentPage]);

	useEffect(() => {
		setPagesArray(range(pagesToShow, startRangeFrom));
	}, [startRangeFrom]);

	return (
		<Pager Elem="button" aria-label="Table pagination" extraClasses="mt-4">
			<PagerArrow
				href="#"
				side="prev"
				aria-label="Previous page"
				disabled={currentPage == 1}
				onClick={handlePrevClick}
				data-testid="prevArrow"
			/>
			{/* first page */}
			{pagesArray[0] !== 1 && (
				<PagerItem
					active={currentPage === 1}
					onClick={() => onPageChange(1)}
					key="firstPage"
					data-testid="firstPageItem"
				>
					{1}
				</PagerItem>
			)}
			{isPrevDotsShown ? (
				<>
					<PagerItem key="prevDots" disabled>
						...
					</PagerItem>
				</>
			) : null}
			{/* active pages window */}
			{pagesArray.map((page, index) => {
				return (
					<PagerItem
						active={currentPage === page}
						onClick={() => onPageChange(page)}
						key={index}
						data-testid={`page_${page}`}
					>
						{page}
					</PagerItem>
				);
			})}
			{isFurtherDotsShown ? (
				<>
					<PagerItem key="furtherDots" disabled>
						...
					</PagerItem>
				</>
			) : null}
			{/* last page */}
			{pagesArray[pagesArray.length - 1] !== pagesAmount && (
				<PagerItem
					active={currentPage === pagesAmount}
					onClick={() => onPageChange(pagesAmount)}
					key="lastPage"
					data-testid="page_last"
				>
					{pagesAmount}
				</PagerItem>
			)}
			<PagerArrow
				href="#"
				side="next"
				aria-label="Next page"
				disabled={currentPage === pagesAmount}
				onClick={handleNextClick}
				data-testid="nextArrow"
			/>
		</Pager>
	);
};

Pagination.displayName = "Pagination(Pager)";
