export const EmailDeliveryStatuses = [
    {
        value: "SUCCESS",
        appearance: "success"
    },
    {
        value: "SENT",
        appearance: "success"
    },
    {
        value: "FAILED",
        appearance: "danger"
    },
    {
        value: "PENDING",
        appearance: "warning"
    },
    {
        value: "PROCESSING",
        appearance: "warning"
    }
];