import { useState, useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useErrorStatus } from "../../utils/hooks";
// components
import { RidesTable, RidesSearch } from "./components";
import { NoResultsMessage, Pagination } from "../../components";
import {
	PageContainer,
	Spinner,
	Heading,
	Notification,
	NotificationContainer,
} from "@flixbus/honeycomb-react";
// utils
import { loginRequest } from "../../authConfig";
import { getRides } from "./Rides.service";
import {
	clearEmptyFields,
	isLocalhost,
	authenticate,
	addUserData,
} from "../../utils";
// constants
import { DefaultPageSize, InitialRideFormValues } from "../../constants";
import * as Constants from "../../constants";
import Moment from "moment";
import { RidesMock } from "../../constants/RidesMockup";

function Rides() {
	const { instance, accounts } = useMsal();
	const request = {
		...loginRequest,
		account: accounts[0],
	};
	const isAuthenticated = useIsAuthenticated();
	const [token, setToken] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [pagesAmount, setPagesAmount] = useState(1);
	const [selectedPage, setSelectedPage] = useState(1);
	const [filterValues, setFilterValues] = useState(InitialRideFormValues);
	const [totalRides, setTotalRides] = useState(0);
	const [rides, setRides] = useState([]);
	const [alertMessage, setAllertMessage] = useState("");
	const [showNotification, setShowNotification] = useState(false);
	const [errorStatus, setErrorStatus] = useState(null);
	useErrorStatus(errorStatus);

	const paginationComponent = (
		<Pagination
			pagesAmount={pagesAmount}
			currentPage={selectedPage}
			onPageChange={(page) => changePage(page)}
		/>
	);

	useEffect(() => {
		if (isAuthenticated && !!accounts.length) {
			instance
				.acquireTokenSilent(request)
				.then((response) => {
					if (response) {
						setToken(response.idToken);
					} else {
						console.log("no token");
					}
				})
				.catch((e) => {
					console.error(e);
				});
		} else {
			if (isLocalhost()) {
				// for local env
				console.log("Not authenticated on local host");
			} else {
				authenticate(instance);
			}
			setIsLoading(false);
		}
	}, [accounts]);

	// as soon as token is aquired make initial API call to get default rides
	useEffect(() => {
		if (isLocalhost() && !isAuthenticated) {
			// mock data
			setRides(RidesMock);
			return;
		}
		if (token) {
			searchRides(InitialRideFormValues, 1);
		}
	}, [token]);

	const searchRides = (formValues = InitialRideFormValues, page = 1) => {
		if (formValues) {
			let body = { ...formValues };
			// lineTitle is not needed
			body.lineTitle = "";
			if (body.fromDateTime) {
				// take from date from the calendar or take today's date
				body.fromDateTime = Moment(
					body.fromDateTime || new Date(),
					Constants.DateFullYear
				).format(Constants.DateSearchFormat);
				// toDateTime is + 1 day to fromDate
				body.toDateTime = Moment(body.fromDateTime)
					.add(1, "days")
					.format(Constants.DateSearchFormat);
			} else {
				// if fromDateTime is not set, set fromDateTime to today's date
				body.fromDateTime = Moment(new Date(), Constants.DateFullYear).format(
					Constants.DateSearchFormat
				);
				// toDateTime is + 1 day to fromDate
				body.toDateTime = Moment(body.fromDateTime)
					.add(1, "days")
					.format(Constants.DateSearchFormat);
				setFilterValues((prev) => {
					return {
						...prev,
						fromDateTime: null,
						toDateTime: null,
					};
				});
			}
			body = clearEmptyFields(body);

			if (body) {
				setIsLoading(true);
				body = addUserData(body, accounts[0]);
				getRides(token, body, page)
					.then((response) => {
						if (response && response.status === 200 && response.data?.content) {
							setRides(response.data.content);
							setPagesAmount(response.data.totalPages || 1);
							setTotalRides(response.data.totalElements);
						} else {
							console.log(response);
							let errorMessage = `${
								response.message || Constants.DefaultErrorMessage
							}`;
							showError(errorMessage, response.response?.status);
						}
						setIsLoading(false);
					})
					.catch((e) => {
						console.log(e);
						setIsLoading(false);
					});
			}
		}
	};

	const changePage = (page) => {
		setSelectedPage(page);
		searchRides(filterValues, page);
	};

	function showError(
		message = "Something went wrong, please try again.",
		status = null
	) {
		setAllertMessage(message);
		setShowNotification(true);
		setErrorStatus(status);
	}

	return (
		<>
			<RidesSearch
				onSubmit={(e) => {
					changePage(1);
					setFilterValues(e);
				}}
				onChange={(e) => {
					setFilterValues(e);
				}}
				onError={(message, status) => showError(message, status)}
			/>
			<PageContainer>
				{isLoading ? (
					<div
						className="d-flex justify-content-center py-4"
						aria-live="polite"
						aria-busy={isLoading}
					>
						<Spinner />
					</div>
				) : rides && rides.length ? (
					<>
						<Heading size={4} Elem="h2">
							Rides Found: {totalRides}, Items per page: {DefaultPageSize}
						</Heading>
						<RidesTable rides={rides} pagination={paginationComponent} />
					</>
				) : (
					<NoResultsMessage />
				)}
			</PageContainer>
			{/* Notifications */}
			{showNotification && (
				<NotificationContainer>
					<Notification
						toast
						appearance="danger"
						dismissCallback={() => setShowNotification(false)}
						closeProps={{
							"aria-label": "Close",
							onClick: () => setShowNotification(false),
						}}
					>
						{alertMessage}
					</Notification>
				</NotificationContainer>
			)}
		</>
	);
}

export default Rides;
