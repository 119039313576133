import {
	Autocomplete,
	AutocompleteInput,
	AutocompleteOptions,
	Input,
} from "@flixbus/honeycomb-react";
/* icons */
import { Icon, IconBus } from "@flixbus/honeycomb-icons-react";

export const AutocompleteLineInput = ({
	formValues: values,
	options: lineList,
	loading,
	filtersConstants: Filters,
	handleDebounce,
	handleSelect,
	handleInputChange,
}) => {
	return (
		<>
			{/* line number input with Autocomplete */}
			<Autocomplete
				id={Filters.lineTitle}
				options={lineList}
				value={values.lineTitle || ""}
				onDebounce={(e) => handleDebounce(e.target.value)}
				onSelect={(item) => handleSelect(item)}
			>
				{/* autocomplete input appearance */}
				<AutocompleteInput
					id="autocomplete"
					placeholder="Type to search a Line Number"
					label="Line Number"
					iconLeft={<Icon InlineIcon={IconBus} />}
					loading={loading}
					type="search"
					onBlur={handleInputChange}
					onChange={handleInputChange}
				/>
				{/* dropdown with options */}
				<AutocompleteOptions
					label="Lines"
					optionsToDisplay={3}
					renderOption={(line) => (
						<span>
							{line.code} ({line.title})
						</span>
					)}
				/>
			</Autocomplete>
			{/* helper input to store Line ID for api request */}
			<Input
				extraClasses="d-none"
				readOnly
				aria-label={Filters.lineIds}
				id={Filters.lineIds}
				value={values.lineIds}
				name={Filters.lineIds}
			/>
		</>
	);
};
