import { useRef, useState, useEffect } from "react";
import { Formik, useFormikContext } from "formik";
import { getLinesData } from "../../rides/Rides.service";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import Moment from "moment";
/* HC components */
import {
	Input,
	Grid,
	GridCol,
	Button,
	PageContainer,
	Heading,
	FormRow,
	SelectGroup,
	Select,
	SelectOption,
} from "@flixbus/honeycomb-react";
import {
	AutocompleteLineInput,
	DatePickerInput,
	FiltersBar,
} from "../../../components";
/* icons */
import {
	Icon,
	IconArrowDown,
	IconArrowUp,
	IconPhone,
	IconPerson,
	IconFilter,
	IconTicket,
	IconNewsletter,
	IconVoucher,
} from "@flixbus/honeycomb-icons-react";
/* constants */
import * as Constants from "../../../constants";
/* utils */
import {
	isLocalhost,
	isValidInput,
	authenticate,
	setInitialCheckboxesState,
	updateChecboxesStateAfterDelete,
	updateCheckboxesState,
	updateLineNumberInput,
} from "../../../utils";

export const OrdersSearch = ({
	onSubmit,
	onChange,
	onError,
	frontendSources,
	languages: languageList,
	currencies: currencyList,
}) => {
	const formRef = useRef();

	const { instance, accounts } = useMsal();
	const request = {
		...loginRequest,
		account: accounts[0],
	};
	const isAuthenticated = useIsAuthenticated();
	const [lineList, setLineList] = useState([]);
	const [lineLoading, setLineLoading] = useState(false);
	const [token, setToken] = useState(null);
	const [filterValues, setFilterValues] = useState(
		Constants.InitialOrderFormValues
	);
	const [statusOptions, setStatusOptions] = useState(
		Constants.OrderStatusOptions
	);

	const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

	// initial rendering
	useEffect(() => {
		setInitialCheckboxesState(statusOptions, setStatusOptions);

		if (isAuthenticated && !!accounts.length) {
			instance
				.acquireTokenSilent(request)
				.then((response) => {
					if (response) {
						setToken(response.idToken);
					} else {
						console.log("no token");
					}
				})
				.catch((e) => {
					console.error(e);
				});
		} else {
			if (isLocalhost()) {
				// for local env
				console.log("Not authenticated on local host");
			} else {
				authenticate(instance);
			}
		}
	}, [accounts]);

	// when filters on top are changed - update selected checkboxes state
	useEffect(() => {
		updateCheckboxesState(
			setStatusOptions,
			Constants.OrderFilters.statuses,
			filterValues
		);
	}, [filterValues]);

	const handleFilterDelete = (filterName, i) => {
		if (filterName === Constants.OrderFilters.statuses) {
			updateChecboxesStateAfterDelete(
				filterName,
				i,
				setStatusOptions,
				filterValues
			);
		}
		try {
			let filtersState = {
				...filterValues,
				statuses: filterValues.statuses ? [...filterValues.statuses] : [],
				lineIds:
					filterName === Constants.OrderFilters.lineTitle
						? []
						: [...filterValues.lineIds],
			};

			if (Array.isArray(filtersState[filterName])) {
				// for fields with multiple choices
				filtersState[filterName].splice(i, 1);
			} else {
				// for simple inputs
				filtersState[filterName] = "";
			}

			// update form values
			formRef.current?.resetForm({
				values: filtersState,
			});
		} catch (e) {
			console.log(e);
		}
	};

	const handleClearFilters = () => {
		// clear form values
		formRef.current?.resetForm({
			values: Constants.InitialOrderFormValues,
		});
	};

	const filterAutocompleteData = (searchQuery) => {
		if (searchQuery.length > 1) {
			setLineLoading(true);
			getLinesData(token, searchQuery).then((response) => {
				if (response.status == 200) {
					setLineList(response.data);
					setLineLoading(false);
				} else {
					setLineLoading(false);
					onError(response.message, response.response?.status);
				}
			});
		}
	};

	const onLineSelect = (item, setFieldValue, handleChange) => {
		setFieldValue(
			Constants.OrderFilters.lineTitle,
			`${item?.code} - ${item?.title}`
		);
		setFieldValue(Constants.OrderFilters.lineIds, [item.id]);
		setLineList([]);
		handleChange(Constants.OrderFilters.lineTitle);
	};

	const FormObserver = () => {
		const { values } = useFormikContext();

		useEffect(() => {
			setFilterValues(values);
			onChange(values);
		}, [values]);

		return null;
	};

	const onDateSelect = (date, isValid, setFieldValue) => {
		setFieldValue(
			Constants.OrderFilters.dateSearchCondition,
			isValid ? Moment(date).format(Constants.DateFullYear) : null
		);
	};

	return (
		<div className="SearchWrapper">
			<Heading size={1} Elem="h1" extraClasses="text-center SearchHeader">
				Order Search
			</Heading>
			<PageContainer>
				<FiltersBar
					filters={filterValues}
					onFilterDelete={(filterName, index) =>
						handleFilterDelete(filterName, index)
					}
					onClearFilters={handleClearFilters}
				/>
				<Formik
					innerRef={formRef}
					validationSchema={Constants.OrdersSchema}
					initialValues={Constants.InitialOrderFormValues}
					enableReinitialize
					onSubmit={(values) => {
						onSubmit(values);
					}}
					onChange={(e) => {
						setFilterValues(e);
					}}
				>
					{({
						handleChange,
						handleSubmit,
						setFieldValue,
						values,
						errors,
						touched,
					}) => (
						<>
							<FormObserver />
							<form id="orders-search" role="search" aria-label="Orders Search">
								{/* first row */}
								<Grid>
									<GridCol size={12} md={6}>
										<FormRow spacing="3">
											<Input
												name={Constants.OrderFilters.orderId}
												id={Constants.OrderFilters.orderId}
												label="Order ID"
												type="number"
												iconLeft={<Icon InlineIcon={IconTicket} />}
												value={values.orderId || ""}
												onChange={handleChange(Constants.OrderFilters.orderId)}
												valid={isValidInput(
													errors,
													touched,
													Constants.OrderFilters.orderId
												)}
												infoError={errors[Constants.OrderFilters.orderId]}
											/>
										</FormRow>
									</GridCol>
									<GridCol size={12} md={6}>
										<FormRow spacing="3">
											<Input
												name={Constants.OrderFilters.customerEmail}
												id={Constants.OrderFilters.customerEmail}
												label="Customer Email"
												type="search"
												iconLeft={<Icon InlineIcon={IconNewsletter} />}
												value={values.customerEmail || ""}
												onChange={handleChange(
													Constants.OrderFilters.customerEmail
												)}
												valid={isValidInput(
													errors,
													touched,
													Constants.OrderFilters.customerEmail
												)}
												infoError={errors[Constants.OrderFilters.customerEmail]}
											/>
										</FormRow>
									</GridCol>
								</Grid>
								{/* second row */}
								<Grid>
									<GridCol size={12} md={6}>
										<FormRow spacing="3">
											<Input
												name={Constants.OrderFilters.passengerFirstName}
												id={Constants.OrderFilters.passengerFirstName}
												label="Passenger First Name"
												type="search"
												iconLeft={<Icon InlineIcon={IconPerson} />}
												value={values.passengerFirstName || ""}
												onChange={handleChange(
													Constants.OrderFilters.passengerFirstName
												)}
												valid={isValidInput(
													errors,
													touched,
													Constants.OrderFilters.passengerFirstName
												)}
												infoError={
													errors[Constants.OrderFilters.passengerFirstName]
												}
											/>
										</FormRow>
									</GridCol>
									<GridCol size={12} md={6}>
										<FormRow spacing="3">
											<Input
												name={Constants.OrderFilters.passengerLastName}
												id={Constants.OrderFilters.passengerLastName}
												label="Passenger Last Name"
												type="search"
												iconLeft={<Icon InlineIcon={IconPerson} />}
												value={values.passengerLastName || ""}
												valid={isValidInput(
													errors,
													touched,
													Constants.OrderFilters.passengerLastName
												)}
												infoError={
													errors[Constants.OrderFilters.passengerLastName]
												}
												onChange={handleChange(
													Constants.OrderFilters.passengerLastName
												)}
											/>
										</FormRow>
									</GridCol>
								</Grid>
								{/* third row */}
								<Grid>
									<GridCol size={12} md={6}>
										<FormRow spacing="3">
											<Input
												name={Constants.OrderFilters.passengerPhone}
												id={Constants.OrderFilters.passengerPhone}
												label="Passenger Phone"
												type="search"
												iconLeft={<Icon InlineIcon={IconPhone} />}
												value={values.passengerPhone || ""}
												onChange={handleChange(
													Constants.OrderFilters.passengerPhone
												)}
												valid={isValidInput(
													errors,
													touched,
													Constants.OrderFilters.passengerPhone
												)}
												infoError={
													errors[Constants.OrderFilters.passengerPhone]
												}
											/>
										</FormRow>
									</GridCol>
									<GridCol size={12} md={6}>
										<FormRow spacing="3" last={!showAdvancedFilters}>
											<Input
												name={Constants.OrderFilters.voucher}
												id={Constants.OrderFilters.voucher}
												label="Voucher Code"
												type="search"
												iconLeft={<Icon InlineIcon={IconVoucher} />}
												value={values.voucher || ""}
												onChange={handleChange(Constants.OrderFilters.voucher)}
												valid={isValidInput(
													errors,
													touched,
													Constants.OrderFilters.voucher
												)}
												infoError={errors[Constants.OrderFilters.voucher]}
											/>
										</FormRow>
									</GridCol>
								</Grid>

								{/* advanced filters */}
								<div className={` ${showAdvancedFilters ? "" : "d-none"}`}>
									<Grid>
										<GridCol size={12} md={6}>
											<FormRow spacing="3">
												<DatePickerInput
													filterName={
														Constants.OrderFilters.dateSearchCondition
													}
													values={values}
													handleDateChange={handleChange(
														Constants.OrderFilters.dateSearchCondition
													)}
													handleDateSelect={(date, isValid) =>
														onDateSelect(date, isValid, setFieldValue)
													}
												/>
											</FormRow>
										</GridCol>
										<GridCol size={12} md={6}>
											<FormRow spacing="3">
												<AutocompleteLineInput
													formValues={values}
													options={lineList}
													loading={lineLoading}
													filtersConstants={Constants.OrderFilters}
													handleDebounce={(value) =>
														filterAutocompleteData(value)
													}
													handleSelect={(item) => {
														onLineSelect(item, setFieldValue, handleChange);
													}}
													handleInputChange={() =>
														updateLineNumberInput(
															lineList,
															Constants.OrderFilters,
															setFieldValue,
															handleChange
														)
													}
												/>
											</FormRow>
										</GridCol>
									</Grid>
									<Grid>
										<GridCol size={12} md={6}>
											{languageList && (
												<FormRow spacing="3">
													<Select
														id={Constants.OrderFilters.languageName}
														name={Constants.OrderFilters.languageName}
														label="User Language"
														value={values.languageName || ""}
														onChange={handleChange(
															Constants.OrderFilters.languageName
														)}
														placeholder="Select a language"
													>
														{languageList.map((language) => {
															return (
																<SelectOption
																	key={language.id}
																	value={language.name}
																>
																	{language.name}
																</SelectOption>
															);
														})}
													</Select>
												</FormRow>
											)}
										</GridCol>
										<GridCol size={12} md={6}>
											{currencyList && (
												<FormRow spacing="3">
													<Select
														id={Constants.OrderFilters.currency}
														name={Constants.OrderFilters.currency}
														label="Currency"
														placeholder="Select currency"
														value={values.currency || ""}
														onChange={handleChange(
															Constants.OrderFilters.currency
														)}
													>
														{currencyList.map((currency) => {
															return (
																<SelectOption
																	key={currency.id}
																	value={currency.code}
																>
																	{currency.name}
																</SelectOption>
															);
														})}
													</Select>
												</FormRow>
											)}
										</GridCol>
									</Grid>
									<Grid>
										<GridCol size={6} md={3}>
											<FormRow spacing="3">
												<Select
													name={Constants.OrderFilters.type}
													id={Constants.OrderFilters.type}
													label="Order Type"
													value={values.type || ""}
													onChange={handleChange(Constants.OrderFilters.type)}
													placeholder="Select an Order Type"
												>
													{Constants.OrderTypes.map((type) => {
														return (
															<SelectOption value={type.name} key={type.id}>
																{type.label}
															</SelectOption>
														);
													})}
												</Select>
											</FormRow>
										</GridCol>
										<GridCol size={6} md={3}>
											<FormRow spacing="3">
												<Input
													name={Constants.OrderFilters.orderValue}
													id={Constants.OrderFilters.orderValue}
													label="Order Value"
													type="search"
													value={values.orderValue || ""}
													onChange={handleChange(
														Constants.OrderFilters.orderValue
													)}
													valid={isValidInput(
														errors,
														touched,
														Constants.OrderFilters.orderValue
													)}
													infoError={errors[Constants.OrderFilters.orderValue]}
												/>
											</FormRow>
										</GridCol>
										<GridCol size={12} md={6}>
											<FormRow spacing="3">
												<Input
													name={Constants.OrderFilters.transactionId}
													id={Constants.OrderFilters.transactionId}
													label="PayPal Transaction ID"
													type="search"
													value={values.paypalAuthorizationId || ""}
													onChange={handleChange(
														Constants.OrderFilters.transactionId
													)}
													valid={isValidInput(
														errors,
														touched,
														Constants.OrderFilters.transactionId
													)}
													infoError={
														errors[Constants.OrderFilters.transactionId]
													}
												/>
											</FormRow>
										</GridCol>
									</Grid>

									<Grid>
										<GridCol size={12} md={6}>
											{frontendSources && (
												<FormRow spacing="3">
													<Select
														id={Constants.OrderFilters.frontendSourceLabel}
														name={Constants.OrderFilters.frontendSourceLabel}
														label="Frontend Source"
														value={values.frontendSourceLabel || ""}
														placeholder="Select a Frontend Source"
														onChange={handleChange(
															Constants.OrderFilters.frontendSourceLabel
														)}
													>
														{frontendSources.map((source) => {
															return (
																<SelectOption
																	key={source.id}
																	value={source.label}
																>
																	{source.label}
																</SelectOption>
															);
														})}
													</Select>
												</FormRow>
											)}
										</GridCol>
										<GridCol size={6} md={3}>
											<FormRow spacing="3">
												<Input
													name={Constants.OrderFilters.paymentHash}
													id={Constants.OrderFilters.paymentHash}
													label="Payment Hash"
													type="search"
													value={values.paymentHash || ""}
													onChange={handleChange(
														Constants.OrderFilters.paymentHash
													)}
													valid={isValidInput(
														errors,
														touched,
														Constants.OrderFilters.paymentHash
													)}
													infoError={errors[Constants.OrderFilters.paymentHash]}
												/>
											</FormRow>
										</GridCol>
										<GridCol size={6} md={3}>
											<FormRow spacing="3">
												<Input
													name={Constants.OrderFilters.pspReference}
													id={Constants.OrderFilters.pspReference}
													label="Payment PSP Reference"
													type="search"
													value={values.pspReference || ""}
													onChange={handleChange(
														Constants.OrderFilters.pspReference
													)}
													valid={isValidInput(
														errors,
														touched,
														Constants.OrderFilters.pspReference
													)}
													infoError={
														errors[Constants.OrderFilters.pspReference]
													}
												/>
											</FormRow>
										</GridCol>
									</Grid>
									<Grid>
										<GridCol size={12}>
											<FormRow spacing="3">
												<SelectGroup
													multi={true}
													label="Status"
													options={statusOptions}
													id={Constants.OrderFilters.statuses}
													name={Constants.OrderFilters.statuses}
													value={values.statuses || []}
													onChange={handleChange(
														Constants.OrderFilters.statuses
													)}
												/>
											</FormRow>
										</GridCol>
									</Grid>
								</div>
								<Grid>
									<GridCol size={12} md={6}>
										<FormRow spacing="3">
											<Button
												appearance="link"
												extraClasses="bigBtn"
												title="Advanced Filters"
												aria-label="Advanced Filters"
												onClick={() =>
													setShowAdvancedFilters(!showAdvancedFilters)
												}
											>
												<Icon
													aria-hidden="true"
													aria-label="Filters icon"
													InlineIcon={IconFilter}
													style={{ marginRight: "5px" }}
												/>
												Advanced Filters
												<span className="dropdownIcon">
													<Icon
														aria-hidden="true"
														aria-label="Filters dropdown"
														InlineIcon={
															showAdvancedFilters ? IconArrowUp : IconArrowDown
														}
													/>
												</span>
											</Button>
										</FormRow>
									</GridCol>
									<GridCol size={12} md={6}>
										<FormRow spacing="3">
											<Button
												extraClasses="bigBtn"
												appearance="primary"
												title="Search"
												display="block"
												aria-label="Submit"
												onClick={handleSubmit}
											>
												Search
											</Button>
										</FormRow>
									</GridCol>
								</Grid>
							</form>
						</>
					)}
				</Formik>
			</PageContainer>
		</div>
	);
};
