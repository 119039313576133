import Moment from "moment";
import { DateAndTimeFullFormat } from "../constants";

export const isCommentUpdated = (comment) => {
	if (!comment || !comment?.latestUpdateBy) {
		return false;
	}
	return !(
		Moment(comment?.latestUpdatedAt).format(DateAndTimeFullFormat) ===
		Moment(comment?.createdAt).format(DateAndTimeFullFormat)
	);
};
