import React, { useEffect, useState } from "react";
// components
import { Checkbox, DataTableRow, Tag } from "@flixbus/honeycomb-react";
import { Price, RouteName } from "../../../../components";

export const RefundItemTableRow = ({
	orderItem,
	key,
	onItemSelection = () => {},
	isSelectedItem,
	isDisabledItem = false,
}) => {
	const [isSelectedRow, setIsSelectedRow] = useState(false);

	useEffect(() => {
		if (orderItem && isSelectedItem) {
			setIsSelectedRow(true);
		} else {
			setIsSelectedRow(false);
		}
	}, [isSelectedItem]);

	const handleRowSelection = () => {
		// set selected row in Refund Table
		setIsSelectedRow((prevChecked) => !prevChecked);
		// pass event to parent
		onItemSelection(orderItem);
		return;
	};

	return orderItem ? (
		<>
			<DataTableRow
				key={key}
				data-testid="refund-item-row"
				checkbox={
					<Checkbox
						small
						aria-label={"Select Order Item"}
						checked={isDisabledItem ? false : isSelectedRow}
						onChange={handleRowSelection}
						id={orderItem.id}
						value={orderItem.id}
						disabled={isDisabledItem}
					/>
				}
			>
				<td>
					<div className=" ">
						<span>{orderItem.id}</span>
					</div>
				</td>
				{/* route info */}
				<td>
					<div>
						<RouteName
							orderItem={orderItem}
							interconnection={orderItem.interconnectionId}
						/>
					</div>
				</td>
				{/* passenger info */}
				<td>
					<span>
						{orderItem.passenger?.firstName} {orderItem.passenger?.lastName}
					</span>
				</td>
				{/* price info */}
				<td>
					<div className="orderPrice">
						<Price value={orderItem.priceGross} currency={orderItem.currency} />
					</div>
				</td>
				{/* type */}
				<td>
					<span>{orderItem.type}</span>
				</td>
			</DataTableRow>
		</>
	) : null;
};
