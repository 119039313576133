import React from "react";
import { Box, Grid, GridCol, Heading } from "@flixbus/honeycomb-react";
import { ValueWithCoping } from "../../../../components";
export const RefundOrderDetails = ({ order, payment }) => {
	return (
		<div>
			<Box extraClasses="OrderDetails">
				<Heading size={3} extraClasses="OrderDetails__header">
					Order Details
				</Heading>
				<Grid>
					<GridCol size={6} md={3}>
						<p className="OrderDetails__label">
							<b>Order</b>
						</p>
						<p>
							<ValueWithCoping dispayFullValue value={order.id} />
						</p>
					</GridCol>
					<GridCol size={6} md={3}>
						<p className="OrderDetails__label">
							<b>Method</b>
						</p>
						<p className="OrderDetails__value">
							{payment && payment.method ? payment.method : "N/A"}
						</p>
					</GridCol>
					<GridCol size={6} md={3}>
						<p className="OrderDetails__label">
							<b>PSP</b>
						</p>
						<p className="OrderDetails__value">
							{payment && payment.psp ? payment.psp : "N/A"}
						</p>
					</GridCol>
					<GridCol size={6} md={3}>
						<p className="OrderDetails__label">
							<b>PSP Reference</b>
						</p>
						<p>
							{payment && payment.pspReference ? (
								<ValueWithCoping value={payment.pspReference} />
							) : (
								"N/A"
							)}
						</p>
					</GridCol>
				</Grid>
			</Box>
		</div>
	);
};
