import React, { useEffect, useState } from "react";
import Moment from "moment";
/* constants */
import {
	DateAndTimeShortYearWithoutSecFormat,
	TIMEZONES,
} from "../../../constants";
/* styles */
import "./CommentsHistory.scss";
import { EditableCommentCell } from "../EditableCommentCell/EditableCommentCell";

export const CommentsHistory = ({
	comments,
	onCommentEdit,
	onCommentDelete,
}) => {
	const [commentsRows, setCommentsRows] = useState([]);

	// generate rows for the comments table
	useEffect(() => {
		if (comments && comments.length) {
			// sort orders by created date
			comments.sort((a, b) => {
				return new Date(b.createdAt) - new Date(a.createdAt);
			});
			let commentsRows = [];
			for (let [comment] of comments.entries()) {
				// each comment row
				let commentRow = [
					// comment text with textarea to edit it
					<EditableCommentCell comment={comment} />,
					// created
					<span>
						{comment?.createdBy?.username}
						<br />
						{Moment(comment?.createdAt).format(
							DateAndTimeShortYearWithoutSecFormat
						)}{" "}
						{TIMEZONES.CET}
					</span>,
					// updated
					<span>
						{comment?.latestUpdateBy?.username || "-"}
						<br />
						{comment?.latestUpdatedAt
							? Moment(comment?.latestUpdatedAt).format(
									DateAndTimeShortYearWithoutSecFormat
							  )
							: null}
					</span>,
				];
				commentsRows.push(commentRow);
			}
			setCommentsRows(commentsRows);
		}
	}, [comments]);

	return commentsRows.length === 0 ? (
		<div className="InfoMessage">
			<span>There are no comments yet</span>
		</div>
	) : (
		<div data-testid="comments-history-table" className="CommentsHistory">
			{/* alternative option to show comments in a table */}
			{/* <Table
				rows={commentsRows}
				headers={CommentsHeaders}
				extraClasses="CommentsHistoryTable"
			/> */}
			{comments.map((comment) => {
				return (
					<div className="CommentRow">
						<EditableCommentCell
							comment={comment}
							handleCommentEdit={(comment, commentId) =>
								onCommentEdit(comment, commentId)
							}
							handleCommentDelete={(commentId) => onCommentDelete(commentId)}
						/>
					</div>
				);
			})}
		</div>
	);
};
