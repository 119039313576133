// component for displaying a ride tag with a link to the RideViewer and a color for the Line
import React, { useEffect } from "react";
import { Tag } from "@flixbus/honeycomb-react";
import { DefaultTagColor, RIDE_VIEWER_ENDPOINT } from "../../constants";
import { isValidColor } from "../../utils";
import "./RideTag.scss";

export const RideTag = ({ route }) => {
	const [tagColor, setTagColor] = React.useState(DefaultTagColor);

	useEffect(() => {
		if (route && route.lineColor) {
			const color = isValidColor(route.lineColor)
				? "#" + route.lineColor
				: DefaultTagColor;
			setTagColor(color);
		}
	}, [route]);

	return route ? (
		<Tag
			Elem="a"
			href={`${RIDE_VIEWER_ENDPOINT}${route.rideId || route.legacyRideId}`}
			display="subtle"
			tagColor={tagColor}
			aria-label="Open Ride in RideViewer"
			title="Open Ride in RideViewer"
			target="_blank"
			data-testid={`RideTag`}
			extraClasses="RideColoredTag"
		>
			{/* if it's ride obj - show uid, for orderItems uid is not present - then show concession, backup version: rideId */}
			{route.uid || route.concession || `Ride #${route.rideId}`}
		</Tag>
	) : (
		<span>N/A</span>
	);
};
