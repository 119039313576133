import { useNavigate } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
// components
import {
	Grid,
	GridCol,
	PageContainer,
	Box,
	Link,
} from "@flixbus/honeycomb-react";
import { QuickOrderSearch } from "../orders/components";
// utils
import { authenticate } from "../../utils";
import AccessGuard from "../../auth/AccessGuard";
import { USER_ROLES } from "../../auth/UserRoles";
// const
import HomeFeatures from "./HomeFeatures";
// styles
import "./Home.scss";

function Home() {
	const navigate = useNavigate();
	const isAuthenticated = useIsAuthenticated();
	const { instance } = useMsal();

	const handleFeatureClick = (path) => {
		if (isAuthenticated) {
			navigate(path);
		} else {
			authenticate(instance);
		}
	};

	return (
		<PageContainer>
			<div className="m-5 px-5">
				<h2>What is BirdView?</h2>
				<p>
					BirdView is a tool for Customer Service to search for bookings
					(orders) and rides, view details of a booking and modify them.
					Additionally, it provides the functionality to search for vouchers and
					generate them (work in progress). This tool is your swiss army knife
					when it comes to customer support.
				</p>
				{/* <p>
					Additional information can be found on{" "}
					<Link
						href="https://wiki.flix.tech/pages/viewpage.action?pageId=175230747"
						target="_blank"
					>
						BirdView wiki page
					</Link>
				</p> */}
				<AccessGuard
					allowedAzureGroups={[USER_ROLES.SUPER_USER, USER_ROLES.USER]}
					showMessage={false}
				>
					<p>
						If you have any thoughts or ideas regarding BirdView or noticed
						something wrong, please open a ticket in our{" "}
						<Link
							href="https://flixtech.atlassian.net/servicedesk/customer/portal/4/group/13"
							target="_blank"
						>
							Service Desk.
						</Link>
					</p>
				</AccessGuard>
			</div>
			<Grid justify="center" extraClasses={"pt-5"}>
				{HomeFeatures.map((header) => {
					return (
						<GridCol size={12} md={4} key={header.name}>
							<Box
								onClick={() => handleFeatureClick(header.path)}
								extraClasses="FeatureBox pb-0"
								role="button"
							>
								<div className="imageWrapper">
									<img className="FeatureImage" src={header.imgUrl} />
								</div>
								<div className="FeatureBody">
									<h5>{header.name}</h5>
									<div>{header.description}</div>
									{header.name === "Orders" ? <QuickOrderSearch /> : null}
								</div>
							</Box>
						</GridCol>
					);
				})}
			</Grid>
		</PageContainer>
	);
}

export default Home;
