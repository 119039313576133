import React from "react";
import { DataTableRow, Tag } from "@flixbus/honeycomb-react";
import { DateAndTime, Price, ValueWithCoping } from "../../../../../components";
import { OrderStatusOptions, TIMEZONES } from "../../../../../constants";

export const PaymentTableRow = ({ key, payment, currency }) => {
	const checkTagAppearance = () => {
		if (payment && payment.status === undefined) {
			try {
				const status = OrderStatusOptions.find(
					(status) =>
						status.value.toLowerCase() === payment.status.toLowerCase()
				);
				return status ? status.appearance : "";
			} catch (error) {
				console.error("Error in PaymentTableRow.jsx: ", error);
			}
			return "";
		}
	};

	return (
		<DataTableRow key={key}>
			<td>
				<span>{payment.id}</span>
			</td>
			<td>
				<Tag appearance={checkTagAppearance()}>{payment.status}</Tag>
			</td>
			<td>
				<span>
					<DateAndTime
						timeStamp={payment.createdAt}
						isDepartureType={false}
						timeZone={TIMEZONES.CET}
					/>
				</span>
			</td>
			<td>
				<span>{payment.psp}</span>
			</td>
			<td>
				<span>
					<ValueWithCoping value={payment.pspReference} />
				</span>
			</td>
			<td>
				<span>{payment.method}</span>
			</td>
			<td>
				<span data-testid="payment-amount">
					<Price value={payment.amount} currency={currency} />
				</span>
			</td>
			<td>
				<span>
					<Price value={payment.amountRefunded} currency={currency} />
				</span>
			</td>
			<td>
				{payment.paypalAuthorizationId ? (
					<ValueWithCoping value={payment.paypalAuthorizationId} />
				) : (
					<span>N/A</span>
				)}
			</td>
			<td>
				<ValueWithCoping value={payment.paymentHash} />
			</td>
		</DataTableRow>
	);
};
