import { graphConfig } from "../authConfig";
/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */
export async function callMsGraph(accessToken) {
	const headers = new Headers();
	const bearer = `Bearer ${accessToken}`;

	headers.append("Authorization", bearer);

	const options = {
		method: "GET",
		headers: headers,
	};

	return fetch(graphConfig.graphMeEndpoint, options)
		.then((response) => response.json())
		.catch((error) => console.log(error));
}

// function to get user's profile picture from Microsoft Graph
export async function getProfilePicture(accessToken) {
	const headers = new Headers();
	const bearer = `Bearer ${accessToken}`;

	headers.append("Authorization", bearer);

	const options = {
		method: "GET",
		headers: headers,
	};

	return fetch(graphConfig.graphMeEndpoint + "/photo/$value", options)
		.then((response) => response.blob())
		.then((blob) => {
			console.log(blob);
			// check that the blob is not empty
			if (blob.size === 0) {
				return null;
			}
			const urlCreator = window.URL || window.webkitURL;
			return urlCreator.createObjectURL(blob);
		})
		.catch((error) => console.log(error));
}
