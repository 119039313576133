import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from "react";
import { loginRequest } from "../authConfig";
import { callMsGraph, getProfilePicture } from "../utils/graph";
import { HeaderUserWidget } from "@flixbus/honeycomb-react";
import { SignOutButton } from "./SignOutButton";
import { HeaderSubNavigation, NavItem } from "@flixbus/honeycomb-react";
import {
	Icon,
	IconPersonBusiness,
	IconPin,
	IconAccountSettings,
} from "@flixbus/honeycomb-icons-react";
import { UserAvatar } from "./UserAvatar/UserAvatar";
import { isLocalhost } from "../utils";

export const Profile = () => {
	const { instance, accounts } = useMsal();
	const [userData, setUserData] = useState(null);
	const [userRoles, setUserRoles] = useState(null);
	const [userPicture, setUserPicture] = useState(null);

	const name = (accounts[0] && accounts[0].name) || "BirdView User";

	useEffect(() => {
		if (instance && accounts && accounts.length > 0) {
			if (!isLocalhost()) {
				RequestProfileData();
			}
		}
	}, [instance, accounts]);

	const RequestProfileData = () => {
		const request = {
			...loginRequest,
			account: accounts[0],
		};

		// Silently acquires an access token which is then attached to a request for Microsoft Graph data
		instance
			.acquireTokenSilent(request)
			.then((response) => {
				callMsGraph(response.accessToken).then((response) => {
					setUserData(response);
				});
				// get user roles from idTokenClaims
				const userRoles = response.account.idTokenClaims.roles;
				setUserRoles(userRoles);
				console.log("User Roles: ", userRoles);
				// get user avatar
				getProfilePicture(response.accessToken).then((response) => {
					// console.log("Profile Picture: ", response);
					setUserPicture(response);
				});
			})
			.catch((e) => {
				console.log(e);
				localStorage.clear();
				// redirect to login
				instance.loginRedirect(loginRequest);
			});
	};

	return (
		<div className="d-flex align-items-center">
			<HeaderUserWidget
				url="#"
				avatar={
					userPicture ? (
						<UserAvatar imgUrl={userPicture} />
					) : (
						<Icon InlineIcon={IconPersonBusiness} size={4} />
					)
				}
				subMenu={
					<HeaderSubNavigation alignment="left" id="pikachu-sub-menu">
						{userData ? (
							<>
								<NavItem href="">
									<b>
										<Icon InlineIcon={IconPersonBusiness} size={4} />
									</b>{" "}
									{userData?.jobTitle || "Job Title"}
								</NavItem>
								<NavItem href="">
									<b>
										<Icon InlineIcon={IconPin} size={4} />
									</b>{" "}
									{userData?.officeLocation || "Office Location"}
								</NavItem>
							</>
						) : null}
						{userRoles ? (
							<NavItem href="">
								<b>
									<Icon InlineIcon={IconAccountSettings} size={4} />
								</b>{" "}
								{userRoles.map((role, index) => (
									// display coma separated roles
									<span key={index}>
										{role}
										{index < userRoles.length - 1 ? ", " : ""}
									</span>
								))}
							</NavItem>
						) : null}
						<NavItem href="">
							<SignOutButton />
						</NavItem>
					</HeaderSubNavigation>
				}
			>
				{name}
			</HeaderUserWidget>
		</div>
	);
};
