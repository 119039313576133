import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@flixbus/honeycomb-react";

export const SignOutButton = () => {
	const { instance } = useMsal();

	const handleLogout = (logoutType) => {
		if (logoutType === "redirect") {
			instance.logoutRedirect({
				postLogoutRedirectUri: "/",
			});
		}
	};

	return (
		<Button
			display="block"
			appearance="primary"
			title="Sign Out"
			onClick={() => handleLogout("redirect")}
		>
			Sign out
		</Button>
	);
};
