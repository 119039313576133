import { API_ENDPOINT } from "../constants";

export async function getLanguages(accessToken) {

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", 'application/json');
    
    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(`${API_ENDPOINT}languages/all`, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}