export const RidesMock = [
	{
		id: "ff1930c2-9d5d-4094-ab27-572f8ae4391a",
		legacyId: "182793993",
		createdAt: "2022-09-30T15:38:21",
		tripNumber: "",
		uid: "LCRO137 TN04",
		status: "on_sale",
		lineCode: "LCRO137 TN04",
		departure: "2024-03-25T04:45:00",
		departureStop: {
			station: {
				id: "18528",
				code: "TEC",
				name: "Tecuci FlixBus stop",
			},
			city: {},
			time: {
				departure: "2024-03-25T07:45:00",
			},
		},
		arrivalStop: {
			station: {
				id: "62361",
				code: "VASL",
				name: "Vaslui (OMV)",
			},
			city: {},
			time: {
				arrival: "2024-03-26T05:30:00",
			},
		},
		tags: [],
	},
	{
		id: "faf1e61d-9290-45da-b308-7632cb854c1d",
		legacyId: "182792223",
		createdAt: "2022-09-30T15:38:12",
		tripNumber: "",
		uid: "LCRO137 TN13",
		status: "on_sale",
		lineCode: "LCRO137 TN13",
		lineColor: "d02e77",
		departure: "2024-03-25T09:15:00",
		departureStop: {
			station: {
				id: "62361",
				code: "VASL",
				name: "Vaslui (OMV)",
			},
			city: {},
			time: {
				departure: "2024-03-24T08:15:00",
			},
		},
		arrivalStop: {
			station: {
				id: "18528",
				code: "TEC",
				name: "Tecuci FlixBus stop",
			},
			city: {},
			time: {
				arrival: "2024-03-26T10:00:00",
			},
		},
		tags: [],
	},
	{
		id: "eddb423a-b1f9-4f41-94cf-d516462ed90e",
		legacyId: "182787443",
		createdAt: "2022-09-30T15:37:48",
		tripNumber: "",
		uid: "LCRO137 TN16",
		status: "on_sale",
		lineCode: "LCRO137 TN16",
		departure: "2024-03-25T19:15:00",
		departureStop: {
			station: {
				id: "18528",
				code: "TEC",
				name: "Tecuci FlixBus stop",
			},
			city: {},
			time: {
				departure: "2024-04-25T23:45:00",
			},
		},
		arrivalStop: {
			station: {
				id: "62361",
				code: "VASL",
				name: "Vaslui (OMV)",
			},
			city: {},
			time: {
				arrival: "2024-04-26T05:00:00",
			},
		},
		tags: [],
	},
	{
		id: "ec8c984c-3c46-4b7e-b7fc-d133b5564331",
		legacyId: "182786933",
		createdAt: "2022-09-30T15:37:46",
		tripNumber: "",
		uid: "LCRO137 TN17",
		status: "on_sale",
		lineCode: "LCRO137 TN17",
		departure: "2024-03-25T12:45:00",
		departureStop: {
			station: {
				id: "62361",
				code: "VASL",
				name: "Vaslui (OMV)",
			},
			city: {},
			time: {
				departure: "2024-03-25T11:45:00",
			},
		},
		arrivalStop: {
			station: {
				id: "18528",
				code: "TEC",
				name: "Tecuci FlixBus stop",
			},
			city: {},
			time: {
				arrival: "2024-03-25T13:30:00",
			},
		},
		tags: [],
	},
	{
		id: "de8eedf3-066e-4a42-abf8-c3e2a80b43d3",
		legacyId: "182781943",
		createdAt: "2022-09-30T15:37:21",
		tripNumber: "",
		uid: "LCRO137 TN12",
		status: "on_sale",
		lineCode: "LCRO137 TN12",
		departure: "2024-03-25T15:45:00",
		departureStop: {
			station: {
				id: "18528",
				code: "TEC",
				name: "Tecuci FlixBus stop",
			},
			city: {},
			time: {
				departure: "2024-03-25T14:45:00",
			},
		},
		arrivalStop: {
			station: {
				id: "62361",
				code: "VASL",
				name: "Vaslui (OMV)",
			},
			city: {},
			time: {
				arrival: "2024-03-25T16:30:00",
			},
		},
		tags: [],
	},
	{
		id: "d608e38a-f805-49d7-89aa-9e68504cc32e",
		legacyId: "182778753",
		createdAt: "2022-09-30T15:37:03",
		tripNumber: "",
		uid: "LCRO137 TN11",
		status: "on_sale",
		lineCode: "LCRO137 TN11",
		departure: "2024-03-25T07:45:00",
		departureStop: {
			station: {
				id: "62361",
				code: "VASL",
				name: "Vaslui (OMV)",
			},
			city: {},
			time: {
				departure: "2024-03-25T06:45:00",
			},
		},
		arrivalStop: {
			station: {
				id: "18528",
				code: "TEC",
				name: "Tecuci FlixBus stop",
			},
			city: {},
			time: {
				arrival: "2024-03-25T08:30:00",
			},
		},
		tags: [],
	},
	{
		id: "b7d7751e-4883-4e6c-baa0-7d30713a2dc1",
		legacyId: "182760283",
		createdAt: "2022-09-30T15:35:41",
		tripNumber: "",
		uid: "LCRO137 TN20",
		status: "on_sale",
		lineCode: "LCRO137 TN20",
		departure: "2024-03-25T22:15:00",
		departureStop: {
			station: {
				id: "18528",
				code: "TEC",
				name: "Tecuci FlixBus stop",
			},
			city: {},
			time: {
				departure: "2024-03-25T21:15:00",
			},
		},
		arrivalStop: {
			station: {
				id: "62361",
				code: "VASL",
				name: "Vaslui (OMV)",
			},
			city: {},
			time: {
				arrival: "2024-03-25T23:00:00",
			},
		},
		tags: [],
	},
	{
		id: "b31277dc-762a-46fa-b057-edf3a4cc4076",
		legacyId: "182755063",
		createdAt: "2022-09-30T15:35:19",
		tripNumber: "",
		uid: "LCRO137 TN19",
		status: "on_sale",
		lineCode: "LCRO137 TN19",
		departure: "2024-03-25T21:15:00",
		departureStop: {
			station: {
				id: "62361",
				code: "VASL",
				name: "Vaslui (OMV)",
			},
			city: {},
			time: {
				departure: "2024-03-25T20:15:00",
			},
		},
		arrivalStop: {
			station: {
				id: "18528",
				code: "TEC",
				name: "Tecuci FlixBus stop",
			},
			city: {},
			time: {
				arrival: "2024-03-25T22:00:00",
			},
		},
		tags: [],
	},
	{
		id: "8c3c4be8-4298-45a4-93ee-bdc2710e7fa7",
		legacyId: "182749943",
		createdAt: "2022-09-30T15:34:58",
		tripNumber: "",
		uid: "LCRO137 TN18",
		status: "on_sale",
		lineCode: "LCRO137 TN18",
		departure: "2024-03-25T20:45:00",
		departureStop: {
			station: {
				id: "18528",
				code: "TEC",
				name: "Tecuci FlixBus stop",
			},
			city: {},
			time: {
				departure: "2024-03-25T19:45:00",
			},
		},
		arrivalStop: {
			station: {
				id: "62361",
				code: "VASL",
				name: "Vaslui (OMV)",
			},
			city: {},
			time: {
				arrival: "2024-03-25T21:30:00",
			},
		},
		tags: [],
	},
	{
		id: "7e7900fa-cc65-4a5a-bf28-f4bbd3f8e0aa",
		legacyId: "182738983",
		createdAt: "2022-09-30T15:34:11",
		tripNumber: "",
		uid: "LCRO137 TN14",
		status: "on_sale",
		lineCode: "LCRO137 TN14",
		departure: "2024-03-25T17:15:00",
		departureStop: {
			station: {
				id: "18528",
				code: "TEC",
				name: "Tecuci FlixBus stop",
			},
			city: {},
			time: {
				departure: "2024-03-25T16:15:00",
			},
		},
		arrivalStop: {
			station: {
				id: "62361",
				code: "VASL",
				name: "Vaslui (OMV)",
			},
			city: {},
			time: {
				arrival: "2024-03-25T18:00:00",
			},
		},
		tags: [],
	},
];
