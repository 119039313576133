/* component with a calendar date picker for rides form */
/* TODO: add parameters to search with before and after condition */

import { Calendar, Input } from "@flixbus/honeycomb-react";
import { Icon, IconCalendar } from "@flixbus/honeycomb-icons-react";
import { CreatedAtMinDate, OrderFilters } from "../../constants";
import { useState } from "react";
import { isValidDate } from "../../utils";

export const DatePickerInput = ({
	values,
	filterName,
	label = "Created At",
	handleDateSelect,
	handleDateChange,
}) => {
	const [selectedDate, setSelectedDate] = useState(null);
	const [showCalendar, setShowCalendar] = useState(false);
	// valueDate is used to display the date in the input field based on the filterName
	const valueDate =
		filterName === OrderFilters.dateSearchCondition
			? values.dateSearchCondition
			: values.fromDateTime;

	const onDateSelect = (date) => {
		if (date && isValidDate(date)) {
			setSelectedDate(date);
			handleDateSelect(date, true);
		} else {
			setSelectedDate(null);
			handleDateSelect(date, false);
		}
		setShowCalendar(false);
	};

	return (
		<div data-testid="date-picker-input">
			<Input
				label={label}
				placeholder="Select a date"
				iconLeft={<Icon InlineIcon={IconCalendar} />}
				id={filterName}
				data-testid={filterName}
				name={filterName}
				onFocus={() => setShowCalendar(true)}
				value={valueDate || ""}
				readOnly={true}
				onChange={handleDateChange}
			/>
			<div className="CalendarRelativeWrapper">
				<div className="CalendarWrapper" data-testid="calendar-wrapper">
					<Calendar
						id="date-picker"
						hidden={!showCalendar}
						startDate={CreatedAtMinDate}
						endDate={new Date()}
						defaultMonth={new Date()}
						appearance={"compact"}
						selected={selectedDate}
						handleSelect={(date) => onDateSelect(date)}
					/>
				</div>
			</div>
		</div>
	);
};
