import axios from "axios";
import { API_ENDPOINT } from "../constants";

export async function addComment(accessToken, body, orderItemId) {
	const bearer = `Bearer ${accessToken}`;

	const config = {
		headers: { Authorization: bearer, "Content-Type": "application/json" },
	};

	return axios
		.post(`${API_ENDPOINT}order-item-comment/${orderItemId}`, body, config)
		.then((response) => response)
		.catch((error) => {
			console.log(error);
			return error;
		});
}

export async function editComment(
	accessToken,
	orderItemId,
	commentId,
	comment,
	user
) {
	const bearer = `Bearer ${accessToken}`;

	const config = {
		headers: { Authorization: bearer, "Content-Type": "application/json" },
	};

	const requestBody = {
		comment: comment,
		userInfo: user,
	};

	return axios
		.patch(
			`${API_ENDPOINT}order-item-comment/${orderItemId}/comment/${commentId}`,
			requestBody,
			config
		)
		.then((response) => response)
		.catch((error) => {
			console.log(error);
			return error;
		});
}
