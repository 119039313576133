import React, { useState } from "react";
import { DateShortTime, DateShortYear } from "../../constants";
import Moment from "moment";
// icons
import {
	Icon,
	IconBusTime,
	IconCalendar,
	IconTrip,
	IconRideInterconnection,
	IconInterconnection,
} from "@flixbus/honeycomb-icons-react";
// styles
import "./RouteName.scss";
import { InterconnectionStatus } from "../../constants/Interconnection";
import {
	Popup,
	Button,
	PopupSection,
	Heading,
	Tag,
	Tooltip,
	List,
} from "@flixbus/honeycomb-react";
import { DateAndTime } from "../DateAndTime/DateAndTime";

export const RouteName = ({
	orderItem,
	interconnection = InterconnectionStatus.noConnection,
}) => {
	const [isPopupOpen, setIsPopupOpen] = useState(false);

	const openInterconnectionInfo = () => {
		setIsPopupOpen(true);
	};
	const handleClose = () => {
		// close the popup
		setIsPopupOpen(false);
	};

	return (
		<>
			<Tooltip
				content={orderItem.fullRouteName || orderItem.route || ""}
				data-testid="route-tooltip"
				id="route-tooltip"
				openOnHover
			>
				<div data-testid="route-name">
					{/* stop names */}
					<div className="RouteInfo">
						<Icon
							InlineIcon={IconTrip}
							size={4}
							extraClasses="RouteInfo__icon"
						/>
						<b>{orderItem.route || "N/A"}</b>
						{/* icon to show if the ride has interconnections */}
						{interconnection &&
						interconnection.toUpperCase() ==
							InterconnectionStatus.withConnection ? (
							<Tag
								display="outlined"
								extraClasses="InterconnectionLabel"
								title="Interconnection"
							>
								<Icon
									InlineIcon={IconRideInterconnection}
									size={3}
									alt="with interconnection"
								/>
							</Tag>
						) : null}
					</div>
					{/* departure time  */}
					{orderItem.departAt ? (
						<>
							<span className="RouteInfo">
								<Icon
									InlineIcon={IconCalendar}
									size={4}
									extraClasses="RouteInfo__icon"
								/>
								{Moment(orderItem.departAt).format(DateShortYear)}
							</span>
							<span className="RouteInfo">
								<Icon
									InlineIcon={IconBusTime}
									size={4}
									extraClasses="RouteInfo__icon"
								/>
								{Moment(orderItem.departAt).format(DateShortTime)}
							</span>
						</>
					) : null}
				</div>
			</Tooltip>
			{/* temporary solution
			TODO: remove this after the design is updated */}
			{isPopupOpen && (
				<Popup
					aria-labelledby="interconnection-popup-heading"
					active={true}
					onOverlayClick={handleClose}
					data-testid="interconnection-popup"
				>
					<PopupSection type="title">
						<Heading
							id="interconnection-popup-heading"
							data-testid="interconnection-popup-heading"
							size={2}
							Elem="h3"
						>
							Interconnection Info
						</Heading>
					</PopupSection>

					<PopupSection type="content">
						{/* for each route in orderItem.routes */}
						<div>
							{orderItem.routes &&
								orderItem.routes.map((route, index) => (
									<div className="InterconnectionRow">
										<div className="InterconnectionRow__icon">
											<Icon InlineIcon={IconInterconnection} size={4} />
										</div>
										<div className="InterconnectionRow__info">
											<p>
												{/* route name */}
												<Tag>{route.fullRouteName}</Tag>
											</p>
											<p>
												{/* ride with a link to RideViewer */}
												<Tag
													Elem="a"
													href={`https://rideviewer.flixbus.com/ride-view/${
														route.rideId || route.legacyRideId
													}`}
													appearance="success"
													aria-label="Open Ride in RideViewer"
													target="_blank"
													extraClasses="InterconnectionRow__ride"
												>
													{route.concession}
												</Tag>
											</p>

											{/* departure time */}
											<DateAndTime timeStamp={route.departure} />
										</div>
									</div>
								))}
						</div>
					</PopupSection>

					<PopupSection type="actions">
						{/* close button is always visible for the popup */}
						<Button
							id="interconnection-popup-close"
							appearance={"primary"}
							onClick={handleClose}
						>
							Close
						</Button>
					</PopupSection>
				</Popup>
			)}
		</>
	);
};
