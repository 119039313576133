import React, { useEffect, useState } from "react";
// components
import { Link, Tag, DataTableRow } from "@flixbus/honeycomb-react";
import {
	DateAndTime,
	RideInfo,
	RideTag,
	ValueWithCoping,
} from "../../../../components";
// constants
import { RIDE_VIEWER_ENDPOINT } from "../../../../constants";
// utils
import { getStationName } from "../../../../utils";
// styles
import "./RidesTableRow.scss";

export const RidesTableRow = ({ ride }) => {
	// departure and arrival stops are objects with the station and time
	const [departureObj, setDepartureObj] = useState(null);
	const [arrivalObj, setArrivalObj] = useState(null);
	// departure and arrival stations are strings
	const [departureStation, setDepartureStation] = useState("");
	const [arrivalStation, setArrivalStation] = useState("");

	// when ride is changed we need to update the departure and arrival objects
	useEffect(() => {
		if (ride) {
			setDepartureObj(ride.departureStop || null);
			setArrivalObj(ride.arrivalStop || null);
		}
	}, [ride]);

	// when arrival and departure objects are changed we need to update the station names
	useEffect(() => {
		if (departureObj) {
			setDepartureStation(getStationName(departureObj));
		}
	}, [departureObj]);

	useEffect(() => {
		if (arrivalObj) {
			setArrivalStation(getStationName(arrivalObj));
		}
	}, [arrivalObj]);

	return ride ? (
		<DataTableRow key={ride?.id}>
			<td>
				<Link
					href={RIDE_VIEWER_ENDPOINT + ride?.id || "" + "/timeline"}
					target="_blank"
					aria-label="RideViewer link"
				>
					<ValueWithCoping value={ride?.id} />
				</Link>
			</td>
			<td>
				<RideTag route={ride} />
			</td>
			<td>
				<RideInfo
					departureStation={departureStation}
					departureObj={departureObj}
					arrivalStation={arrivalStation}
					arrivalObj={arrivalObj}
				/>
			</td>
			<td>
				<span>
					<DateAndTime timeStamp={ride.createdAt} isDepartureType={false} />
				</span>
			</td>
			<td>
				<span>
					<Tag display="outlined">{ride.status}</Tag>
				</span>
			</td>
			<td>
				<Link
					href={RIDE_VIEWER_ENDPOINT + ride.id + "/timeline"}
					target="_blank"
					aria-label="RideViewer link"
				>
					Open in RideViewer
				</Link>
			</td>
		</DataTableRow>
	) : null;
};
