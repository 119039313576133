import { Heading } from "@flixbus/honeycomb-react";
import notFoundImg from "../../assets/Search-Binoculars.svg";
import "./NoResultsMessage.scss";

export const NoResultsMessage = () => {
	return (
		<div className="NoResultsBox" data-testid="NoResultsMessage">
			<img src={notFoundImg} alt="No results found" title="No results" />
			<Heading size={2} Elem="h2" extraClasses="text-center">
				No results found
			</Heading>
			<p className="text-center">
				Please change your search parameters and try again
			</p>
		</div>
	);
};
