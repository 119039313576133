export const shortenId = (id) => {
	if (!id) {
		return null;
	}
	// no need to shorten if id is already short
	if (id.length <= 6) {
		return id;
	}
	// leave first 2 and last 2 characters, others replace with ...
	return id.slice(0, 2) + "..." + id.slice(-4);
};
