// type can take the following values: "payment", "voucher", "orders"
import React from "react";
// components
import { Heading, Dropdown, Button } from "@flixbus/honeycomb-react";
import {
	Icon,
	IconCash,
	IconVoucher,
	IconPartner,
} from "@flixbus/honeycomb-icons-react";
// auth
import AccessGuard from "../../../../auth/AccessGuard";
import { USER_ROLES } from "../../../../auth/UserRoles";
// styles
import "./SectionHeader.scss";

export const SectionHeader = ({ type, headerText, orderId = null }) => {
	const PaymentsActionsArray = [
		{ text: "PSP Refund", href: `/orders/${orderId}/refund`, target: "_blank" },
	];

	return (
		<Heading sectionHeader size={3} extraClasses="SectionHeader">
			<span className="SectionHeader__name">
				{type === "payment" && (
					<Icon InlineIcon={IconCash} size={5} extraClasses="HeaderIcon" />
				)}
				{type === "voucher" && (
					<Icon InlineIcon={IconVoucher} size={5} extraClasses="HeaderIcon" />
				)}
				{type === "orders" && (
					<Icon InlineIcon={IconPartner} size={5} extraClasses="HeaderIcon" />
				)}
				{headerText}
			</span>
			{/* for payment section there should be a dropdown on the right side with the action buttons for PSP Refund,
			available only to REFUNDUSER */}
			{type === "payment" ? (
				<AccessGuard
					allowedAzureGroups={[USER_ROLES.REFUND_USER]}
					showMessage={false}
				>
					<Dropdown
						links={PaymentsActionsArray}
						xPosition="left"
						data-testid="payments-options-button"
					>
						<Button
							display="square"
							aria-label="Open Payment Actions"
							extraClasses="SectionHeader__additionalMenu"
						>
							<span className="Dots">...</span>
						</Button>
					</Dropdown>
				</AccessGuard>
			) : null}
		</Heading>
	);
};
