import { useRef, useState, useEffect } from "react";
import { Formik, useFormikContext } from "formik";
import { getLinesData } from "../../Rides.service";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../../authConfig";
import Moment from "moment";
/* utils */
import {
	isValidInput,
	setInitialCheckboxesState,
	updateChecboxesStateAfterDelete,
	updateCheckboxesState,
	isLocalhost,
	authenticate,
	updateLineNumberInput,
} from "../../../../utils";
/* components */
import {
	Input,
	Grid,
	GridCol,
	SelectGroup,
	Button,
	PageContainer,
	Heading,
	FormRow,
} from "@flixbus/honeycomb-react";
import {
	AutocompleteLineInput,
	DatePickerInput,
	FiltersBar,
} from "../../../../components";
/* icons */
import { Icon, IconTicket, IconTrip } from "@flixbus/honeycomb-icons-react";
/* constants */
import * as Constants from "../../../../constants";
import {
	InitialRideFormValues,
	RideFilters,
	RidesSchema,
} from "../../../../constants";

export const RidesSearch = ({ onSubmit, onChange, onError }) => {
	const formRef = useRef();

	const { instance, accounts } = useMsal();
	const request = {
		...loginRequest,
		account: accounts[0],
	};
	const isAuthenticated = useIsAuthenticated();
	const [lineList, setLineList] = useState([]);
	const [lineLoading, setLineLoading] = useState(false);
	const [token, setToken] = useState(null);
	const [filterValues, setFilterValues] = useState(InitialRideFormValues);
	const [statusOptions, setStatusOptions] = useState(
		Constants.RideStatusOptions
	);
	const [weekdaysOptions, setWeekdaysOptions] = useState(
		Constants.WeekDaysOptions
	);

	// initial rendering
	useEffect(() => {
		setInitialCheckboxesState(statusOptions, setStatusOptions);
		setInitialCheckboxesState(weekdaysOptions, setWeekdaysOptions);
		// set today as a default fromDate
		formRef.current?.setFieldValue(
			RideFilters.fromDateTime,
			Moment().format(Constants.DateFullYear)
		);

		if (isAuthenticated && !!accounts.length) {
			instance
				.acquireTokenSilent(request)
				.then((response) => {
					if (response) {
						setToken(response.idToken);
					} else {
						console.log("no token");
					}
				})
				.catch((e) => {
					console.error(e);
				});
		} else {
			if (isLocalhost()) {
				// for local env
				console.log("Not authenticated on local host");
			} else {
				authenticate(instance);
			}
		}
	}, [accounts]);

	// when filters on top are changed - update selected checkboxes state
	useEffect(() => {
		updateCheckboxesState(setStatusOptions, RideFilters.statuses, filterValues);
		updateCheckboxesState(
			setWeekdaysOptions,
			RideFilters.dayOfWeeks,
			filterValues
		);
	}, [filterValues]);

	const filterAutocompleteData = (searchQuery) => {
		if (searchQuery.length > 1) {
			setLineLoading(true);
			getLinesData(token, searchQuery).then((response) => {
				if (response.status == 200) {
					setLineList(response.data);
					setLineLoading(false);
				} else {
					setLineLoading(false);
					onError(response.message, response.response?.status);
				}
			});
		}
	};

	const onLineSelect = (item, setFieldValue, handleChange) => {
		// string representation of line code and title for a user
		setFieldValue(RideFilters.lineTitle, `${item?.code} - ${item?.title}`);
		// need to pass line code to the new API instead of ID
		setFieldValue(RideFilters.lineIds, [item.code]);
		setLineList([]);
		handleChange(RideFilters.lineTitle);
	};

	const onDateSelect = (date, isValid, setFieldValue) => {
		setFieldValue(
			RideFilters.fromDateTime,
			isValid ? Moment(date).format(Constants.DateFullYear) : null
		);
	};

	const FormObserver = () => {
		const { values } = useFormikContext();

		useEffect(() => {
			setFilterValues(values);
			onChange(values);
		}, [values]);

		return null;
	};

	const handleFilterDelete = (filterName, i) => {
		// for select group components manually uncheck
		if (filterName === RideFilters.statuses) {
			updateChecboxesStateAfterDelete(
				filterName,
				i,
				setStatusOptions,
				filterValues
			);
		} else if (filterName === RideFilters.dayOfWeeks) {
			updateChecboxesStateAfterDelete(
				filterName,
				i,
				setWeekdaysOptions,
				filterValues
			);
		}
		try {
			let filtersState = {
				...filterValues,
				statuses: [...filterValues.statuses],
				dayOfWeeks: [...filterValues.dayOfWeeks],
				lineIds:
					filterName === RideFilters.lineTitle ? [] : [...filterValues.lineIds],
			};

			if (Array.isArray(filtersState[filterName])) {
				// for fields with multiple choices
				filtersState[filterName].splice(i, 1);
			} else {
				// for simple inputs
				filtersState[filterName] = "";
			}

			// update form values
			formRef.current?.resetForm({
				values: filtersState,
			});
		} catch (e) {
			console.log(e);
		}
	};

	const handleClearFilters = () => {
		// clear form values
		formRef.current?.resetForm({
			values: InitialRideFormValues,
		});
	};

	return (
		<div className="SearchWrapper">
			<Heading size={1} Elem="h1" extraClasses="text-center SearchHeader">
				Ride Search
			</Heading>
			<PageContainer>
				<FiltersBar
					filters={filterValues}
					onFilterDelete={(filterName, index) =>
						handleFilterDelete(filterName, index)
					}
					onClearFilters={handleClearFilters}
				/>
				<Formik
					innerRef={formRef}
					initialValues={Constants.InitialRideFormValues}
					enableReinitialize
					validationSchema={RidesSchema}
					onSubmit={(values) => {
						onSubmit(values);
					}}
				>
					{({
						handleChange,
						handleBlur,
						handleSubmit,
						setFieldValue,
						values,
						errors,
						touched,
					}) => (
						<>
							<FormObserver />
							<form id="rides-search" role="search" aria-label="Rides Search">
								<Grid>
									<GridCol size={12} md={6}>
										<FormRow spacing="3">
											<Input
												name={RideFilters.id}
												id={RideFilters.id}
												label="Ride ID"
												iconLeft={<Icon InlineIcon={IconTicket} />}
												type="search"
												value={values.id || ""}
												onChange={handleChange(RideFilters.id)}
												valid={isValidInput(errors, touched, RideFilters.id)}
												infoError={errors[RideFilters.id]}
											/>
										</FormRow>
									</GridCol>
									<GridCol size={12} md={6}>
										<FormRow spacing="3">
											<Input
												name={RideFilters.tripNumberShortCode}
												id={RideFilters.tripNumberShortCode}
												label="Trip number"
												iconLeft={<Icon InlineIcon={IconTrip} />}
												type="search"
												value={values.tripNumberShortCode || ""}
												onChange={handleChange(RideFilters.tripNumberShortCode)}
												valid={isValidInput(
													errors,
													touched,
													RideFilters.tripNumberShortCode
												)}
												infoError={errors[RideFilters.tripNumberShortCode]}
											/>
										</FormRow>
									</GridCol>
								</Grid>
								<Grid extraClasses="mt-2">
									<GridCol size={12} md={6}>
										<FormRow spacing="3">
											<AutocompleteLineInput
												formValues={values}
												options={lineList}
												loading={lineLoading}
												filtersConstants={RideFilters}
												handleDebounce={(value) =>
													filterAutocompleteData(value)
												}
												handleSelect={(item) => {
													onLineSelect(item, setFieldValue, handleChange);
												}}
												handleInputChange={() =>
													updateLineNumberInput(
														lineList,
														RideFilters,
														setFieldValue,
														handleChange
													)
												}
											/>
										</FormRow>
									</GridCol>
									<GridCol size={12} md={6}>
										<FormRow spacing="3">
											<DatePickerInput
												filterName={RideFilters.fromDateTime}
												values={values}
												handleDateChange={handleChange(
													RideFilters.fromDateTime
												)}
												handleDateSelect={(date, isValid) =>
													onDateSelect(date, isValid, setFieldValue)
												}
												label="Departure date"
											/>
										</FormRow>
									</GridCol>
								</Grid>
								<Grid>
									<GridCol size={12}>
										<FormRow spacing="3">
											<SelectGroup
												multi={true}
												label="Status"
												options={statusOptions}
												id={RideFilters.statuses}
												name={RideFilters.statuses}
												value={values.statuses || []}
												onChange={handleChange(RideFilters.statuses)}
											/>
										</FormRow>
									</GridCol>
								</Grid>
								<Grid>
									<GridCol size={12}>
										<FormRow spacing="3">
											<SelectGroup
												multi={true}
												label="Select weekdays"
												options={weekdaysOptions}
												id={RideFilters.dayOfWeeks}
												name={RideFilters.dayOfWeeks}
												value={values.dayOfWeeks || []}
												onChange={handleChange(RideFilters.dayOfWeeks)}
											/>
										</FormRow>
									</GridCol>
								</Grid>
								<Grid>
									<GridCol size={12} md={6} pushMd={6}>
										<FormRow spacing="3">
											<Button
												extraClasses="bigBtn"
												appearance="primary"
												title="Search"
												display="block"
												onClick={handleSubmit}
											>
												Search
											</Button>
										</FormRow>
									</GridCol>
								</Grid>
							</form>
						</>
					)}
				</Formik>
			</PageContainer>
		</div>
	);
};
