import { Tag, Textarea } from "@flixbus/honeycomb-react";
import { Button, ButtonGroup } from "@flixbus/honeycomb-react";
import "./EditableCommentCell.scss";
import { useState } from "react";
import { Icon, IconEdit, IconDelete } from "@flixbus/honeycomb-icons-react";
import Moment from "moment";
import { isCommentUpdated } from "../../../utils/isCommentUpdated";
import {
	DateAndTimeShortYearWithoutSecFormat,
	TIMEZONES,
} from "../../../constants";
import { USER_ROLES } from "../../../auth/UserRoles";
import AccessGuard from "../../../auth/AccessGuard";

export const EditableCommentCell = ({
	comment = "",
	handleCommentEdit,
	handleCommentDelete,
}) => {
	const [isEditMode, setIsEditMode] = useState(false);
	const [commentValue, setCommentValue] = useState(comment?.comment);

	const handleCancel = () => {
		setIsEditMode(false);
	};
	const handleSaveClick = () => {
		// pass event handler to parent component
		handleCommentEdit(commentValue, comment.id);
	};

	const handleDeleteClick = (id) => {
		// pass event handler to parent component
		handleCommentDelete(id);
	};

	return isEditMode ? (
		// textarea to edit a comment
		<div data-testid="CommentEditMode">
			<Textarea
				name="updateComment"
				data-testid="updateCommentTextarea"
				id={comment?.id}
				label="Update the comment"
				defaultValue={comment?.comment}
				onChange={(e) => setCommentValue(e.target.value)}
			/>
			<ButtonGroup alignment="end" extraClasses="commentEditButtons">
				<Button
					size="sm"
					data-testid="saveCommentButton"
					appearance="primary"
					onClick={() => handleSaveClick()}
				>
					Save
				</Button>
				<Button
					size="sm"
					data-testid="cancelCommentButton"
					onClick={() => handleCancel()}
				>
					Cancel
				</Button>
			</ButtonGroup>
		</div>
	) : (
		// view mode
		<div className="CommentTextCell" data-testid="CommentViewMode">
			<div className="CommentContent">
				<p className="CommentCreated">
					<span className="CommentAuthor">
						{comment?.author || comment?.createdBy?.username}
					</span>{" "}
					{Moment(comment?.createdAt).format(
						DateAndTimeShortYearWithoutSecFormat
					)}{" "}
					{TIMEZONES.CET}
					{comment?.isOldComment ? (
						<Tag small display="outlined" extraClasses="CommentLabel">
							Old Backend
						</Tag>
					) : null}
				</p>
				<p data-testId="CommentText">{comment?.comment}</p>
				{isCommentUpdated(comment) ? (
					<p className="CommentEdited">
						Edited{" "}
						{Moment(comment?.latestUpdatedAt).format(
							DateAndTimeShortYearWithoutSecFormat
						)}{" "}
						by {comment?.latestUpdateBy?.username}
					</p>
				) : null}
			</div>
			<ButtonGroup orientation="horizontal">
				{/* only user and superuser can edit comments */}
				<AccessGuard
					allowedAzureGroups={[USER_ROLES.SUPER_USER, USER_ROLES.USER]}
					showMessage={false}
				>
					<Button
						size="sm"
						appearance="link"
						data-testid="editCommentButton"
						aria-label="Edit Comment"
						title="Edit Comment"
						onClick={() => setIsEditMode(true)}
					>
						<Icon InlineIcon={IconEdit} size="3" />
					</Button>
				</AccessGuard>
				{/* deletion of comments is available only for super users */}
				<AccessGuard
					allowedAzureGroups={[USER_ROLES.SUPER_USER]}
					showMessage={false}
				>
					<Button
						size="sm"
						appearance="link"
						data-testid="deleteCommentButton"
						aria-label="Delete Comment"
						title="Delete Comment"
						// TODO: handler to delete a comment
						onClick={(comment) => handleDeleteClick(comment?.id)}
					>
						<Icon InlineIcon={IconDelete} size="3" />
					</Button>
				</AccessGuard>
			</ButtonGroup>
		</div>
	);
};
