import { PageContainer, Heading, Infobox } from "@flixbus/honeycomb-react";
import "../RequestSuccess/RequestSuccess.scss";

export const RequestError = ({ errorObj, orderId }) => {
	return (
		<PageContainer>
			<div className="ResultPage">
				<Infobox aria-label="Refund Request Unsuccessful" appearance="danger">
					<Heading size={2}>
						Order {orderId}: Refund Request Unsuccessful
					</Heading>
					<div className="ResultPage__info">
						{errorObj && errorObj.message ? (
							<>
								<p>
									<b>Error Details: </b>
									{errorObj.message}
								</p>
								<p>
									<b>Error Type:</b>{" "}
									{errorObj.error || errorObj.code || "Uknown type"} (code:{" "}
									{errorObj?.result_code ||
										errorObj.response?.status ||
										"Unknown"}
									)
								</p>
							</>
						) : (
							"An unexpected error occurred and your refund could not be requested. Please return to the Order Details page and try again later."
						)}
					</div>
				</Infobox>
			</div>
		</PageContainer>
	);
};
