import {
	Box,
	Connection,
	ConnectionSlot,
	ConnectionStop,
	GridCol,
	Heading,
} from "@flixbus/honeycomb-react";
import Moment from "moment";
import { DateAndTimeShortYearFormat, TIMEZONES } from "../../../../constants";
import { Icon, IconTime } from "@flixbus/honeycomb-icons-react";
import "./OrderHistory.scss";

export const OrderHistory = ({ orderHistory }) => {
	if (!orderHistory || !orderHistory.length) {
		return null;
	}

	return (
		<GridCol extraClasses="d-flex" md={6} size={12}>
			<Box small extraClasses="OrderHistoryBox" data-testid="order-history">
				<Heading sectionHeader size={3}>
					<Icon InlineIcon={IconTime} size={5} /> Order History ({TIMEZONES.CET}
					)
				</Heading>
				<Connection>
					{orderHistory.map((orderChange) => {
						const time = orderChange.createdAt
							? Moment(orderChange.createdAt).format(DateAndTimeShortYearFormat)
							: "N/A";

						const info = `${orderChange.note}, changed by ${
							orderChange.userFullName || "unknown user"
						}`;
						return (
							<ConnectionStop
								key={orderChange.createdAt}
								station={
									<>
										<b>{orderChange.status || "unknown status"}</b>
										<Box small>
											<p>{info}</p>
										</Box>
									</>
								}
								time={time}
							/>
						);
					})}
				</Connection>
			</Box>
		</GridCol>
	);
};
