// helper function to add user data to the request body for user activity tracking
export const createUserData = (account) => {
	if (account) {
		const user = {
			userPrincipal: account.name || account.username || "unknown",
			email: account.username || "unknown",
			roles: account.idTokenClaims?.roles || "default",
		};
		return user;
	}
	return null;
};

// add user data to the request body for user activity tracking
export const addUserData = (body, account) => {
	if (account) {
		// add user data to the request body
		return {
			...body,
			currentUserData: createUserData(account),
		};
	} else {
		return body;
	}
};
