import { DataTableRow } from "@flixbus/honeycomb-react";
import { Link, Tag } from "@flixbus/honeycomb-react";
import {
	DateAndTime,
	Pagination,
	Phone,
	RoutesList,
} from "../../../../components";
import { PassengerCell } from "../../components";
import { isEmptyPassenger, shortenId } from "../../../../utils";
import * as CONSTANTS from "../../../../constants";
import { anonymizePersonalData } from "../../../../utils/anonymizePersonalData";
import { IconPublic, IconRebook } from "@flixbus/honeycomb-icons-react";
import { Icon } from "@flixbus/honeycomb-icons-react";
import { useEffect, useState } from "react";
// styles
import "./OrdersTableRow.scss";

export const OrdersTableRow = ({ order }) => {
	const [isRebooked, setIsRebooked] = useState(false);

	useEffect(() => {
		let rebookedFlag =
			order.rebookingStatus &&
			order.rebookingStatus.toLowerCase() === CONSTANTS.rebookedStatus;
		setIsRebooked(rebookedFlag);
	});

	return order ? (
		<DataTableRow>
			{/* order ids */}
			<td>
				<Link
					href={`/orders/${order.id}`}
					aria-label="Order details"
					target="_blank"
				>
					{order.id}
				</Link>

				{order.resultOrderId || order.sourceOrderId ? (
					<div className="OrderLinks">
						{order.sourceOrderId ? (
							<p className="margin-0">
								<Link
									href={`/orders/${order.sourceOrderId}`}
									aria-label="Source Order"
									target="_blank"
								>
									{"Src "}
									{`${shortenId(order.sourceOrderId)}`}
								</Link>
							</p>
						) : null}
						{order.resultOrderId ? (
							<p className="margin-0">
								<Link
									href={`/orders/${order.resultOrderId}`}
									aria-label="Result Order"
									target="_blank"
								>
									{`Res ${shortenId(order.resultOrderId)}`}
								</Link>
							</p>
						) : null}
					</div>
				) : null}
			</td>
			{/* created at */}
			<td>
				<DateAndTime timeStamp={order.createdAt} isDepartureType={false} />
			</td>
			{/* status */}
			<td>
				<Tag
					appearance={
						CONSTANTS.OrderStatusOptions.find(
							(status) => status.value == order.status
						)?.appearance
					}
					display="outlined"
				>
					{order.status}
					{isRebooked ? (
						<span className="RebookedLabel">
							<Icon InlineIcon={IconRebook} size={3} title="Rebooked" />
						</span>
					) : null}
				</Tag>
				{order.vouchers && order.vouchers.length && (
					<>
						<br />
						<Tag extraClasses="mt-1" small>
							Promocode
						</Tag>
					</>
				)}
			</td>
			{/* routes */}
			<td>
				<RoutesList orderItems={order.orderItems} />
			</td>
			{/* departure time */}
			<td>
				<DateAndTime timeStamp={order.departAt} />
			</td>
			{/* passengers */}
			<td>
				<div>
					{!!(order.orderItems && order.orderItems.length) &&
						order.orderItems.map((orderItem) => {
							const firstName = orderItem.passenger?.firstName;
							let lastName = orderItem.passenger?.lastName;
							if (!+process.env.REACT_APP_IS_PRODUCTION) {
								lastName = anonymizePersonalData(lastName, "name");
							}

							return !isEmptyPassenger(orderItem.passenger) ? (
								<PassengerCell passengerProp={[firstName, lastName]} />
							) : null;
						})}
				</div>
			</td>
			{/* phone */}
			<td>
				<div>
					<Phone orderItems={order.orderItems} />
				</div>
			</td>
			{/* email */}
			<td>
				<span>
					{!!+process.env.REACT_APP_IS_PRODUCTION
						? order.customerEmail
						: anonymizePersonalData(order.customerEmail, "email")}
				</span>
			</td>
			{/* shop and language */}
			<td>
				<div>
					{order.shop}
					<br />
					{order.language?.name && (
						<Tag display="outlined" small>
							<Icon InlineIcon={IconPublic} />
							{order.language?.name}
						</Tag>
					)}
				</div>
			</td>
		</DataTableRow>
	) : null;
};
