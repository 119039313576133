import axios from "axios";
import { API_ENDPOINT } from "../../constants";

export async function getRides(accessToken, body, page = 1) {
    // TODO: replace size with dynamic parameters
    const size = 10;

    const bearer = `Bearer ${accessToken}`;

    // condition for string parameters
    body.searchCondition = "CONTAINS";
    // condition to search in order values (prices)
    body.numericSearchCondition = "EQUAL";

    const config = {
        headers: { Authorization: bearer, "Content-Type": 'application/json' }
    };
 
    return axios.post(`${API_ENDPOINT}ride/events/search?page=${page}&size=${size}`, body, config)
        .then(response => response)
        .catch(error => {
            console.log(error);
            return error;
    });
}


export async function getLinesData(accessToken, pattern) {
    const bearer = `Bearer ${accessToken}`;

    const config = {
        headers: { Authorization: bearer, "Content-Type": 'application/json' }
    };

    return axios.get(`${API_ENDPOINT}lines/${pattern}`, config)
        .then(response => response)
        .catch(error => {
            console.log(error);
            return error;
    });
}
