import React, { useEffect, useState } from "react";
import {
	Connection,
	ConnectionStop,
	ConnectionTitle,
} from "@flixbus/honeycomb-react";
import Moment from "moment";
import "./RideInfo.scss";
import {
	DateFullYear,
	DateShortTime,
	DateShortYear,
	DayAndMonthFormat,
} from "../../constants";

export const RideInfo = ({
	departureStation,
	departureObj,
	arrivalStation,
	arrivalObj,
}) => {
	const [plusDays, setPlusDays] = useState(null);
	const departureDate = Moment(departureObj?.time?.departure);
	const arrivalDate = Moment(arrivalObj?.time?.arrival);

	useEffect(() => {
		// format arrival and departure dates to have date format without time
		const arrivalDateFormatted = Moment(arrivalDate, DayAndMonthFormat).startOf(
			"day"
		);
		const departureDateFormatted = Moment(
			departureDate,
			DayAndMonthFormat
		).startOf("day");
		// check if arrival date is next date or later after departure date
		const isArrivedOnDifferentDay =
			arrivalDateFormatted !== departureDateFormatted;
		if (isArrivedOnDifferentDay) {
			// calculate how many calendar days between arrival and departure days
			const dateDifference = arrivalDateFormatted.diff(
				departureDateFormatted,
				"days"
			);
			if (dateDifference > 1) {
				setPlusDays(`+${dateDifference} days`);
			} else if (dateDifference === 1) {
				setPlusDays("+1 day");
			}
		} else {
			// when arrival is on the same day as departure, do not display the plusDays label
			setPlusDays(null);
		}
	}, [departureObj, arrivalObj]);

	return (
		<div className="RouteDetails">
			<Connection
				appearance="success"
				title={
					<ConnectionTitle
						text={`${Moment(departureDate).format(DateFullYear)}`}
						plusDays={plusDays}
					/>
				}
				plusDays="+1 day"
			>
				<ConnectionStop
					station={departureStation}
					time={Moment(departureDate).format(DateShortTime)}
				/>
				<ConnectionStop
					station={arrivalStation}
					time={Moment(arrivalDate).format(DateShortTime)}
				/>
			</Connection>
		</div>
	);
};
