export * from "./TableConstants";
export * from "./Dates";
export * from "./FieldNames";
export * from "./ValidationSchemas";
export * from "./APIConstants";
export * from "./UserConstants";
export * from "./EmailDeliveryStatuses";
export * from "./Interconnection";
export * from "./Statuses";
export * from "./Colors";
export { OrdersMockup } from "./OrdersMockup";
export { RidesMock } from "./RidesMockup";
export * from "./Refund";

export const WeekDaysOptions = [
	{ id: "Mo", value: "MONDAY", name: "Monday", label: "Mo" },
	{ id: "Tu", value: "TUESDAY", name: "Tuesday", label: "Tu" },
	{ id: "We", value: "WEDNESDAY", name: "Wednesday", label: "We" },
	{ id: "Th", value: "THURSDAY", name: "Thursday", label: "Th" },
	{ id: "Fr", value: "FRIDAY", name: "Friday", label: "Fr" },
	{ id: "Sa", value: "SATURDAY", name: "Saturday", label: "Sa" },
	{ id: "Su", value: "SUNDAY", name: "Sunday", label: "Su" },
];
export const OrderStatusOptions = [
	{
		id: 1,
		value: "PAID",
		name: "Paid",
		label: "Paid",
		appearance: "success",
		checked: false,
		onChange: (e) => {},
	},
	{
		id: 2,
		value: "CANCELLED",
		name: "Canceled",
		label: "Canceled",
		appearance: "danger",
		checked: false,
		onChange: (e) => {},
	},
	{
		id: 3,
		value: "PENDING",
		name: "Pending",
		label: "Pending",
		appearance: "warning",
		checked: false,
		onChange: (e) => {},
	},
	{
		id: 4,
		value: "REFUNDED",
		name: "Refunded",
		label: "Refunded",
		appearance: "success",
		checked: false,
		onChange: (e) => {},
	},
	{
		id: 5,
		value: "CREDITED",
		name: "Credited",
		label: "Credited",
		appearance: "success",
		checked: false,
		onChange: (e) => {},
	},
	{
		id: 6,
		value: "REBOOKED_CASH",
		name: "Rebooked-Cash",
		label: "Rebooked Cash",
		appearance: "success",
		checked: false,
		onChange: (e) => {},
	},
	{
		id: 7,
		value: "REBOOKED",
		name: "Rebooked",
		label: "Rebooked",
		appearance: "success",
		checked: false,
		onChange: (e) => {},
	},
];
export const OrderTypes = [
	{ id: 0, value: "web", name: "Web", label: "Web" },
	{ id: 1, value: "mobile", name: "Mobile", label: "Mobile" },
	{ id: 2, value: "bus", name: "Bus", label: "Bus" },
	{ id: 3, value: "agency", name: "Agency", label: "Agency" },
	{ id: 4, value: "pos", name: "Pos", label: "POS" },
	{ id: 5, value: "import", name: "Import", label: "Import" },
];

// radio options
export const DiscountTypeOptions = [
	{
		id: "freeTicket",
		value: "free ticket",
		name: "discountType",
		label: "Free Ticket",
	},
	{
		id: "freeBike",
		value: "free bike",
		name: "discountType",
		label: "Free Bike",
	},
	{
		id: "twoFreeTickets",
		value: "two free tickets",
		name: "discountType",
		label: "Two Free Tickets",
	},
	{ id: "percent", value: "percent", name: "discountType", label: "Percent" },
	{ id: "fixed", value: "fixed", name: "discountType", label: "Fixed" },
	{
		id: "accumulative",
		value: "accumulative",
		name: "discountType",
		label: "Accumulative",
	},
];
export const ApplicationTypeOptions = [
	{ id: 1, value: "all", name: "All", label: "All Applications" },
	{ id: 2, value: "web", name: "Web", label: "Web Applications" },
	{ id: 3, value: "mobile", name: "Mobile", label: "Mobile Flix App" },
];
export const VoucherStatusOptions = [
	{ id: 10, value: "Inactive", name: "Inactive", label: "Inactive" },
	{ id: 20, value: "Active", name: "Active", label: "Active" },
	{ id: 30, value: "Expired", name: "Expired", label: "Expired" },
	{ id: 40, value: "Used", name: "Used", label: "Used" },
	{ id: 50, value: "Cancel", name: "Cancel", label: "Cancel" },
];
export const RideStatusOptions = [
	{
		id: 1,
		value: "archived",
		name: "archived",
		label: "Archived",
		checked: false,
		onChange: (e) => {},
	},
	{
		id: 2,
		value: "on_sale",
		name: "on_sale",
		label: "On Sale",
		checked: true,
		onChange: (e) => {},
	},
	{
		id: 3,
		value: "hidden",
		name: "hidden",
		label: "Hidden",
		checked: false,
		onChange: (e) => {},
	},
	{
		id: 4,
		value: "preview",
		name: "preview",
		label: "Preview",
		checked: false,
		onChange: (e) => {},
	},
	{
		id: 5,
		value: "planr_drafted",
		name: "planr_drafted",
		label: "Planr drafted",
		checked: false,
		onChange: (e) => {},
	},
	{ id: 6, value: "ghost", name: "ghost", label: "Ghost", checked: false },
	{
		id: 7,
		value: "drafted",
		name: "drafted",
		label: "Drafted",
		checked: false,
		onChange: (e) => {},
	},
];

export const VoucherTypes = {
	Marketing: "marketing",
	Accompanying: "accompanying",
	Goodwill: "goodwill",
	Rebooking: "rebooking",
};

export const DefaultErrorMessage =
	"Something went wrong. Please try again or contact an admin";
