import { DataTable } from "@flixbus/honeycomb-react";
import { PaymentHeaders } from "../../../../../constants";
import { SectionHeader } from "../../SectionHeader/SectionHeader";
import { PaymentTableRow } from "../../../components";
import { useEffect, useState } from "react";

export const PaymentBasicInfo = ({ order }) => {
	const { payments } = order;
	const [type, setType] = useState(null);

	useEffect(() => {
		if (
			payments &&
			payments.length &&
			!!payments[0].pspReference &&
			parseFloat(payments[0].amount) !== 0
		) {
			setType("payment");
		}
	}, [order]);

	return payments && payments.length ? (
		<>
			<SectionHeader type={type} headerText="Payments" orderId={order.id} />
			<DataTable
				headers={PaymentHeaders}
				scrollable
				extraClasses="SectionTable"
				data-testid="payments-table"
			>
				{payments.map((payment, i) => (
					<PaymentTableRow
						key={i}
						payment={payment}
						currency={order.currency}
						data-testid="payment-row"
					/>
				))}
			</DataTable>
		</>
	) : null;
};
