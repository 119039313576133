/* if there is date parameter was selected in the calendar send it as an obj with "at" key */
import Moment from "moment";
import { DateSearchFormat } from "../constants";
import { isValidFormattedDate } from "./validator";

export const formatDateSearchCondition = (dateSearchCondition) => {
    let dateSearchParamValue;
    if (
        dateSearchCondition &&
        isValidFormattedDate(dateSearchCondition)
    ) {
        let dateSearchParam = Moment(
            dateSearchCondition
        ).format(DateSearchFormat);
        // according to API it should be obj with "at" field
        dateSearchParamValue = dateSearchParam ? {
            at: dateSearchParam,
        } : null;
    } else {
        // if not present or not valid date format
        dateSearchParamValue = null;
    }

    return dateSearchParamValue;
}
