import React from "react";
import {
	Box,
	Button,
	Grid,
	GridCol,
	Heading,
	Infobox,
	PageContainer,
} from "@flixbus/honeycomb-react";
import { Price, ValueWithCoping } from "../../../../components";
import "./RequestSuccess.scss";

const RequestSuccess = ({
	orderId,
	refundAmount,
	currency,
	refundReason = "N/A",
}) => {
	return (
		<PageContainer>
			<div className="ResultPage">
				<Infobox aria-label="Refund Request Successful" appearance="success">
					<Heading size={2}>Order {orderId}: Refund Request Successful</Heading>
					<Grid>
						<GridCol size={6}>
							<div className="ResultPage__info">
								<b>Refund Reason</b>
							</div>
							<div className="ResultPage__info">{refundReason}</div>
						</GridCol>
						<GridCol size={6}>
							<div className="ResultPage__info">
								<b>Amount to Refund</b>{" "}
							</div>
							<div className="ResultPage__info">
								<Price value={refundAmount} currency={currency} />
							</div>
						</GridCol>
					</Grid>
				</Infobox>
			</div>
		</PageContainer>
	);
};

export default RequestSuccess;
