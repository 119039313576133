import "./App.scss";
import "@flixbus/honeycomb-react/dist/css/honeycomb-themes.css";
import "@fontsource/roboto";
// HC components
import {
	Header,
	HeaderBrand,
	HeaderWidgets,
	HeaderBurgerMenu,
} from "@flixbus/honeycomb-react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Grid, GridCol } from "@flixbus/honeycomb-react";
// components
import { Navigation, SignInButton, Profile } from "./components";
// route components
import Orders from "./routes/orders/Orders";
import { OrderDetails } from "./routes/orders/OrderDetails/OrderDetails";
import Rides from "./routes/rides/Rides";
import Vouchers from "./routes/vouchers/Vouchers";
import NewVoucher from "./routes/vouchers/NewVoucher";
import Home from "./routes/home/Home";
import NotFound from "./routes/notFound/NotFound";
import Refund from "./routes/refund/Refund";
// helpers
import { useIsAuthenticated } from "@azure/msal-react";
import { isLocalhost } from "./utils";
// assets
import footerImg from "./assets/Landscape.svg";
import logo from "./assets/birdview_grey_wordmark.svg";
import UnderConstruction from "./routes/underConstruction/UnderConstruction";

function App() {
	let isAuthenticated = useIsAuthenticated();
	// for dev purposes on localhost
	if (isLocalhost()) {
		isAuthenticated = true;
	}

	const userWidget = <>{isAuthenticated ? <Profile /> : <SignInButton />} </>;

	return (
		<>
			<BrowserRouter>
				<Header>
					<HeaderBrand
						alt="BirdView"
						href="/"
						height="60"
						appearance="tall"
						src={logo}
					/>
					{isAuthenticated ? (
						<HeaderBurgerMenu
							openPanelLabel="Open main menu"
							closePanelLabel="Close main menu"
							panelId="burger-panel"
						>
							{Navigation}
						</HeaderBurgerMenu>
					) : null}
					{isAuthenticated ? Navigation : null}
					<HeaderWidgets>{userWidget}</HeaderWidgets>
				</Header>
				<>
					{isAuthenticated ? (
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="orders" element={<Orders />} />
							<Route path="orders/:id" element={<OrderDetails />} />
							<Route path="rides" element={<Rides />} />
							<Route path="orders/:id/refund" element={<Refund />} />
							{/* TODO: replace with Vouchers component when ready */}
							<Route path="vouchers/search" element={<UnderConstruction />} />
							<Route
								path="vouchers/newVoucher"
								element={<UnderConstruction />}
							/>
							<Route path="*" element={<NotFound />} />
						</Routes>
					) : (
						<Grid justify="center" align="center" extraClasses="LoginBlock">
							<GridCol size={4}>
								<h3 className="text-center">Sign in to BirdView</h3>
								<div className="d-flex justify-content-center align-items-center">
									<SignInButton />
								</div>
							</GridCol>
						</Grid>
					)}
				</>
				{/* TODO: replace with footer component */}
				<footer>
					<img src={footerImg} width="100%" alt="footer" />
				</footer>
			</BrowserRouter>
		</>
	);
}

export default App;
