import React, { useState } from "react";
// components
import { NotificationContainer, Notification } from "@flixbus/honeycomb-react";
// constants
import * as CONSTANTS from "../../../../constants";
// styles
import "./RefundNotification.scss";

// 2 types of notifications based on refund type: auto_message for selected items and reminder for custom refunds
const RefundNotification = ({ isCustomRefund }) => {
	const [showNotification, setShowNotification] = useState(true);

	return (
		<>
			{showNotification && isCustomRefund !== null ? (
				<NotificationContainer>
					<Notification
						data-testid="refund-notification"
						toast={false}
						extraClasses="RefundNotification"
						dismissCallback={() => setShowNotification(false)}
						appearance={!isCustomRefund ? "success" : "info"}
						closeProps={{
							"aria-label": "Close",
							onClick: () => setShowNotification(false),
						}}
					>
						<p className="RefundNotification__title">
							Comment {isCustomRefund ? " reminder" : " created"}
						</p>
						<p className="RefundNotification__text">
							{!isCustomRefund
								? CONSTANTS.RefundNotifications[
										CONSTANTS.SuccessAlertTypes.auto_message
								  ]
								: CONSTANTS.RefundNotifications[
										CONSTANTS.SuccessAlertTypes.reminder
								  ]}
						</p>
					</Notification>
				</NotificationContainer>
			) : null}
		</>
	);
};

export default RefundNotification;
