export const buildRefundRequestBody = (
    refundAmount = null, maxRefundAmount = null, currency = null, refundReason = null, paymentHash = null, pspReference = null, account = null
) => {
    if (!refundAmount || !currency || !refundReason || !paymentHash || !pspReference) {
        return null;
    };
    return {
        reason: refundReason,
        maximumRefundableAmount: maxRefundAmount,
        user: account?.username || account?.name || 'BV User',
        pspRefundRequestBody: {
            amount: refundAmount,
            currency: currency,
            payment_hash: paymentHash,
            psp_reference: pspReference,
        }
    };
}