import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { loginRequest } from "../../authConfig";

const notAllowedErrorCode = "403";
const unauthorizedErrorCode = "401";

export const useErrorStatus = (status = null) => {
	const { instance } = useMsal();
	useEffect(() => {
		if (status == notAllowedErrorCode || status == unauthorizedErrorCode) {
			localStorage.clear();
			instance.loginRedirect(loginRequest);
		}
	}, [status]);

	return status;
};
