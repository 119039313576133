import React from "react";
import { NavLink } from "react-router-dom";
import {
	HeaderNavigation,
	HeaderSubNavigation,
	NavItem,
	Tag,
} from "@flixbus/honeycomb-react";
//icons
import { Icon, IconNewTab } from "@flixbus/honeycomb-icons-react";

export const Navigation = (
	<HeaderNavigation aria-label="BirdView">
		<NavItem to="/orders" RouterLink={NavLink}>
			Orders
		</NavItem>
		<NavItem to="/rides" RouterLink={NavLink}>
			Rides
		</NavItem>
		<NavItem
			openOnHover
			subMenu={
				<HeaderSubNavigation id="sub-nav">
					<NavItem to="/vouchers/search" RouterLink={NavLink}>
						Search Vouchers
					</NavItem>
					<NavItem to="/vouchers/newVoucher" RouterLink={NavLink}>
						Create New Voucher
					</NavItem>
				</HeaderSubNavigation>
			}
		>
			Vouchers
		</NavItem>
		<NavItem href="https://rebookr.flixbus.com" target="_blank">
			<span>Rebookr</span>
			<Icon InlineIcon={IconNewTab} size={3} />
		</NavItem>
		<NavItem
			href="https://app.oneops.flixbus.com/ops-gis/radar"
			target="_blank"
		>
			<span>Radar</span>
			<Icon InlineIcon={IconNewTab} size={3} />
		</NavItem>
		{!+process.env.REACT_APP_IS_PRODUCTION ? (
			<NavItem disabled>
				<Tag appearance="warning" display="outlined">
					This is DEV version!
				</Tag>
			</NavItem>
		) : null}
	</HeaderNavigation>
);
