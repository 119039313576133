import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, GridCol, PageContainer } from "@flixbus/honeycomb-react";
import notFoundImg from "../../assets/Search-Binoculars.svg";
import "./NotFound.scss";

const NotFound = () => {
	const navigate = useNavigate();

	const handleButtonClick = () => {
		navigate("/");
	};

	return (
		<PageContainer>
			<Grid align="center">
				<GridCol key="grid-btm-a" size={12}>
					<div className="NotFoundContainer">
						<h1>404 Not Found</h1>
						<p>The page you are looking for does not exist.</p>
						<br />
						<img
							src={notFoundImg}
							alt="Not found"
							title="Wrong URL"
							data-testid="notFoundImg"
						/>
						<br />
						<Button appearance="primary" onClick={handleButtonClick}>
							Return to Home Page
						</Button>
					</div>
				</GridCol>
			</Grid>
		</PageContainer>
	);
};

export default NotFound;
