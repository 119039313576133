import { useEffect, useState } from "react";
import Moment from "moment";
// HC components
import { Box, Heading, GridCol, Grid, Tag } from "@flixbus/honeycomb-react";
import {
	Icon,
	IconNewsletter,
	IconCalendar,
} from "@flixbus/honeycomb-icons-react";
// constants
import { DateAndTimeShortYearFormat, TIMEZONES } from "../../../../constants";
// utils
import { getTagAppearanceByStatus } from "../../../../utils/getTagAppearanceByStatus";
// styles
import "./EmailDeliveryInfo.scss";
import { anonymizePersonalData } from "../../../../utils/anonymizePersonalData";

export const EmailDeliveryInfo = ({ deliveries, messageServiceStatus }) => {
	const [emailStatus, setEmailStatus] = useState(null);
	const [serviceStatus, setServiceStatus] = useState(null);
	const [deliveryObj, setDeliveryObj] = useState(null);
	const [tagEmailStatusAppearance, setTagEmailStatusAppearance] =
		useState(null);
	const [tagServiceStatusAppearance, setTagServiceStatusAppearance] =
		useState(null);
	const [invoiceEmailDate, setInvoiceEmailDate] = useState(null);
	const [orderConfirmationEmailDate, setOrderConfirmationEmailDate] =
		useState(null);

	useEffect(() => {
		if (deliveries) {
			// taking the last delivery object
			const lastDelivery = deliveries[deliveries.length - 1];
			setDeliveryObj(lastDelivery);
			if (lastDelivery && lastDelivery?.createdAt) {
				setInvoiceEmailDate(
					Moment(lastDelivery?.createdAt).format(DateAndTimeShortYearFormat)
				);
			}
			// take the latest status
			let emailDeliveryStatus = lastDelivery?.status;
			// business logic requirement
			if (!deliveries.length) {
				emailDeliveryStatus = "Pending";
			}
			setEmailStatus(emailDeliveryStatus);
		}
	}, [deliveries]);

	// retrieve the message service status
	useEffect(() => {
		if (messageServiceStatus) {
			setServiceStatus(messageServiceStatus?.emailStatuses?.eventState);
			setOrderConfirmationEmailDate(
				Moment(messageServiceStatus.emailStatuses?.creationTime).format(
					DateAndTimeShortYearFormat
				)
			);
		}
	}, [messageServiceStatus]);

	// when the email status is set, set the tag appearance
	useEffect(() => {
		if (emailStatus) {
			setTagEmailStatusAppearance(getTagAppearanceByStatus(emailStatus));
		}
	}, [emailStatus]);

	// when the service status is set, set the tag appearance
	useEffect(() => {
		if (serviceStatus) {
			setTagServiceStatusAppearance(getTagAppearanceByStatus(serviceStatus));
		}
	}, [serviceStatus]);

	const formatEmailReceiver = (email) => {
		if (!+process.env.REACT_APP_IS_PRODUCTION) {
			return anonymizePersonalData(email, "email");
		} else {
			return email;
		}
	};

	return (
		<GridCol extraClasses="d-flex" md={6} size={12}>
			<Box small extraClasses="EmailBox" data-testid="email-delivery-info">
				{/* TODO: are deliveries chronologicaly sorted? which obj with a status is the last one? */}
				<Grid>
					<GridCol>
						<Heading sectionHeader size={3}>
							<Icon InlineIcon={IconNewsletter} size={5} /> Message Status
						</Heading>
						{emailStatus || serviceStatus ? (
							<>
								<p>
									<b>Invoice Email: </b>
									{/* Email status */}
									<Tag
										extraClasses="InfoTag"
										display="outlined"
										appearance={tagEmailStatusAppearance}
										title="Email Status"
										data-testid="InvoiceEmailStatus"
									>
										{emailStatus ? emailStatus.toUpperCase() : "N/A"}
									</Tag>
									{/* date when email was sent */}
									{invoiceEmailDate ? (
										<Tag extraClasses="InfoTag" display="outlined">
											<Icon InlineIcon={IconCalendar} size={4} />
											<span data-testid="InvoiceEmailDate">
												{invoiceEmailDate} {TIMEZONES.CET}
											</span>
										</Tag>
									) : null}
									{/* amount of emails sent */}
									{deliveryObj || deliveries ? (
										<Tag
											display="outlined"
											extraClasses="InfoTag"
											title="Amount of emails sent"
										>
											<span data-testid="AmountOfEmails">
												{deliveryObj
													? deliveryObj.deliveryAttempts
													: deliveries.length}
											</span>
											<Icon InlineIcon={IconNewsletter} size={4} />
										</Tag>
									) : null}
								</p>
								<p>
									{/* Message Service Status: all type of emails like booking confirmation, rebooking, cancellation */}
									<span title="Message confirming order change">
										<b>Order Confirmation: </b>{" "}
										<Tag
											extraClasses="InfoTag"
											display="outlined"
											appearance={tagServiceStatusAppearance}
											data-testid="OrderConfirmationEmailStatus"
										>
											{serviceStatus ? serviceStatus.toUpperCase() : "N/A"}
										</Tag>
									</span>
									{/* date when email was sent */}
									{orderConfirmationEmailDate ? (
										<Tag extraClasses="InfoTag" display="outlined">
											<Icon InlineIcon={IconCalendar} size={4} />
											<span data-testid="OrderConfirmationEmailDate">
												{orderConfirmationEmailDate} {TIMEZONES.UTC}
											</span>
										</Tag>
									) : null}
								</p>
								{/* type of email */}
								{serviceStatus ? (
									<p title="Type of confirmation">
										<b>Type:</b>{" "}
										<span data-testid="OrderConfirmationEmailType">
											{messageServiceStatus?.emailStatuses?.type || "Unknown"}
										</span>
									</p>
								) : null}
								{/* receiver of email */}
								<p title="Receiver">
									<b>Receiver:</b>{" "}
									<span data-testid="EmailReceiver">
										{formatEmailReceiver(deliveryObj?.destination) || "N/A"}
									</span>
								</p>
							</>
						) : (
							<p className="NoInfoMessage" data-testid="NoInfoMessage">
								There is no information about email delivery
							</p>
						)}
					</GridCol>
				</Grid>
			</Box>
		</GridCol>
	);
};
