import axios from "axios";
import { API_ENDPOINT } from "../../constants";

export async function sendRefundRequest(body, accessToken) {
	const bearer = `Bearer ${accessToken}`;

	const config = {
		headers: { Authorization: bearer, "Content-Type": "application/json" },
	};

	return axios
		.post(`${API_ENDPOINT}psp-refund/refund-claims`, body, config)
		.then((response) => response)
		.catch((error) => {
			console.log(error);
			return error;
		});
}

