// components
import { DataTable } from "@flixbus/honeycomb-react";
import { OrdersTableRow } from "../../components";
// const
import * as CONSTANTS from "../../../../constants";

export const OrdersTable = ({ orders, pagination }) => {
	return (
		<>
			<DataTable
				plain={false}
				scrollable
				headers={CONSTANTS.OrdersTableHeaders}
				controls={pagination}
			>
				{orders.map((order, i) => (
					<OrdersTableRow key={i} order={order} />
				))}
			</DataTable>
		</>
	);
};
