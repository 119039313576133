import { OrderFilters, RideFilters } from "./FieldNames";
import * as Yup from "yup";

// TODO: add phone number format validation with special characters regex

export const OrdersSchema = Yup.object().shape({
	[OrderFilters.orderId]: Yup.string()
		.min(2, "Order ID is too short")
		.max(20, "Order ID is too long")
		.matches(/^[0-9]+$/, "Must be only digits"),
	[OrderFilters.customerEmail]: Yup.string().email("Invalid email"),
	[OrderFilters.voucher]: Yup.string().max(20, "Voucher value is too long"),
	[OrderFilters.passengerFirstName]: Yup.string()
		.max(50, "Too long")
		.matches(/[\D/@%!"#?¨_.,]+$/, "Must be only letters"),
	[OrderFilters.passengerLastName]: Yup.string()
		.max(50, "Last name is too long")
		.matches(/[\D/@%!"#?¨_.,]+$/, "Must be only letters"),
	[OrderFilters.passengerPhone]: Yup.string()
		.min(7, "Invalid phone format, not enough numbers")
		.max(15, "Invalid phone format, too many numbers"),
	[OrderFilters.orderValue]: Yup.string()
		.max(15, "Order Value is too big")
		.matches(
			/^(\d+(?:[\.||\,]\d+)?)$/,
			"Must be correct number, with dot or comma"
		),
	[OrderFilters.paymentHash]: Yup.string().max(50, "Payment Hash is too long"),
	[OrderFilters.pspReference]: Yup.string().max(
		50,
		"PSP Reference is too long"
	),
	[OrderFilters.transactionId]: Yup.string()
		.min(3, "Transaction ID is too short")
		.max(50, "Transaction ID is too long"),
});

export const RidesSchema = Yup.object().shape({
	[RideFilters.id]: Yup.string()
		.min(2, "Ride ID is too short")
		.max(100, "Ride ID is too long"),
	[RideFilters.tripNumberShortCode]: Yup.string()
		.min(2, "Trip number is too short")
		.max(20, "Trip number is too long"),
});
