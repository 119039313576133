export const OrdersMockup = {
	content: [
		{
			id: "3047962711",
			orderItems: [
				{
					id: "4767607943",
					orderId: "3047962711",
					fromId: "48021",
					toId: "49551",
					route: "ALT - IST",
					interconnectionId: "INTERCONNECTION",
					routes: [
						{
							arrival: "2022-12-16T02:45:00+01:00",
							concession: "LFX35",
							lineColor: "5d0c0a",
							departure: "2022-12-15T22:45:00+01:00",
							fullRouteName:
								"İstanbul Dudullu bus station - Altınoluk bus station",
							route: "IST - ALT",
							priceGross: "29.99",
							type: "adult",
							stopFromCityName: "İstanbul Dudullu bus station",
							stopToCityName: "Altınoluk bus station",
							rideId: "6c8f08f8-cb80-4b25-be64-7edbbda5a583",
							rideLegacyId: "264398763",
						},
						{
							arrival: "2022-12-16T22:45:00+01:00",
							concession: "LFX34",
							departure: "2022-12-16T13:55:00+01:00",
							fullRouteName: "Altınoluk bus station - Ibiza",
							route: "ALT - IBZ",
							priceGross: "266.62",
							stopFromCityName: "Altınoluk bus station",
							stopToCityName: "Ibiza",
							rideId: "6c8f08f8-cb80-4b25-be64-7edbbda5a583",
							rideLegacyId: "264398763",
							lineColor: "23ff45",
						},
					],
					fullRouteName: "İstanbul Dudullu bus station - Altınoluk bus station",
					description:
						"2022-12-15 22:45:00: İstanbul Dudullu bus station - Altınoluk bus station",
					ticketId: "kamilkoc-3029689352",
					rideId: "155424781",
					passengerId: "123123",
					mandateSourceId: "1761",
					mandateSource: null,
					departAt: "2022-12-15T22:45:00+01:00",
					concession: "LN T0123",
					lineColor: "5d0c9b",
					passenger: {
						id: "18121212121",
						firstName: "John",
						lastName: "Test",
						birthDate: null,
						phone: "123-123-123",
						type: "adult",
						createdAt: "2022-12-13T18:41:13",
						updatedAt: "2022-12-13T18:41:13",
					},
					type: "adult",
					priceType: null,
					currency: "EUR",
					currencyId: "201",
					productId: null,
					priceNet: 296.61,
					priceGross: 350.99,
					createdAt: "2022-12-13T18:41:36",
					updatedAt: "2022-12-15T23:05:53",
					comments: [
						{
							comment: "test comment",
							createdAt: "2022-12-13T18:41:36",
							latestUpdatedAt: "2023-08-01T09:11:25.406Z",
							createdBy: {
								username: "Test User",
								email: "string",
								jobTitle: "string",
							},
							latestUpdateBy: {
								username: "Another Agent",
								email: "string",
								jobTitle: "string",
							},
							isOldComment: null,
						},
						{
							id: 123123123,
							comment: "Additional test comment",
							status: "string",
							editHistory: [
								{
									comment: "string",
									updatedBy: {
										username: "John",
										email: "testemail",
										jobTitle: "agent",
									},
									updatedAt: "2023-08-01T09:11:25.406Z",
								},
							],
							createdBy: {
								username: "Sam",
								email: "sam@test.com",
								jobTitle: "string",
							},
							latestUpdateBy: {
								username: "Sam",
								email: "sam@test.com",
								jobTitle: "agent",
							},
							latestUpdatedAt: "2023-08-01T09:11:25.406Z",
							createdAt: "2023-08-01T09:11:25.406Z",
							isOldComment: true,
						},
					],
					stopFromCityName: "İstanbul Dudullu bus station",
					stopToCityName: "Ibiza",
				},
				{
					id: "4767607953",
					orderId: "3047962711",
					fromId: "48021",
					toId: "49551",
					route: "IST - ALT",
					fullRouteName: "İstanbul Dudullu bus station - Altınoluk bus station",
					description:
						"Premium Seat for 2022-12-15 22:45:00: İstanbul Dudullu bus station - Altınoluk bus station",
					ticketId: "kamilkoc-3029689353",
					rideId: "155424781",
					passengerId: null,
					mandateSourceId: "1761",
					mandateSource: null,
					departAt: null,
					concession: "MN T0123",
					lineColor: null,
					passenger: null,
					interconnectionId: null,
					type: "product",
					priceType: null,
					currency: "EUR",
					currencyId: "201",
					productId: null,
					priceNet: 5.93,
					priceGross: 7.99,
					createdAt: "2022-12-13T18:41:36",
					updatedAt: "2022-12-13T18:41:36",
				},
			],
			orderHistory: [
				{
					createdAt: "2023-06-08T12:06:50",
					id: "553654663",
					note: "Rebooking: New order ID #3082610591. Unknown voucher.",
					orderId: "3082610538",
					status: "rebooked",
				},
				{
					createdAt: "2023-06-08T12:08:50",
					id: "553654663",
					note: "Paid: New order ID #3082610591.",
					orderId: "3082610538",
					status: "paid",
				},
			],
			comments: null,
			sourceOrderId: "45367212123",
			resultOrderId: "3047962712",
			customerEmail: "test@gmail.com",
			status: "PAID",
			departAt: "2022-12-15T22:45:00",
			interconnectionId: null,
			reservationId: "6394513963",
			reference: "3047962710",
			vouchers: [
				{
					id: "9245784111",
					prefix: "MBFB",
					code: "MBFB4ZANJV",
					usageType: "single",
					discountType: "two_free_tickets",
					revenueType: "marketing",
					status: "used",
					discount: 0,
					discountOrigin: 0,
					credit: 0,
					creditOrigin: 0,
					specification:
						"Mitarbeiter Personal Vouchers - Two free tickets - Jahr 2022",
					promocodeType: "used",
					createdAt: "2021-12-06T11:02:41",
				},
			],
			urlKey: null,
			user: null,
			userId: null,
			languageId: "138",
			language: { id: "138", name: "Turkish", code: "tr" },
			lineId: null,
			type: "web",
			ticketUrl: "https://www.flixbus.com/booking/ticket/123123123123",
			invoiceUrl: "https://www.flixbus.com/booking/invoice/123123123123",
			shopId: null,
			shop: "flixbus_tr",
			downloadHash: "x06yu17hbmurfeb7lo58qgjv6veig8jtnaxrq8u71ujy8zinb2",
			mandateSourceId: "15",
			mandateSource: null,
			currency: "EUR",
			currencyId: "201",
			value: 357.0,
			discount: 0.0,
			credit: 0.0,
			donation: 0.0,
			returnedDonation: 0.0,
			grandTotal: 357.0,
			baseTotal: 18.1298,
			subtotalNet: 302.54,
			subtotalGross: 357.0,
			refund: 0.0,
			rebookingStatus: "rebooked",
			taxes: 54.46,
			interconnection: false,
			createdAt: "2022-12-13T18:41:36",
			updatedAt: "2022-12-13T18:41:36",
			deliveries: [
				{
					id: "1790517273",
					messageId:
						"010201839c95f7b2-34f2261f-dcbe-415a-9961-355744399ed7-000000",
					status: "success",
					source: "noreply@flixbus.com",
					destination: "Olha.yehorova@flixbus.com",
					timestamp: "2022-10-03T08:43:34",
					createdAt: "2022-10-03T08:43:34",
					updatedAt: "2022-10-03T08:43:38",
					deliveryAttempts: 2,
					orderId: "3035250485",
				},
			],
			messageServiceStatus: {
				emailStatuses: {
					channel: "email",
					creationTime: "2023-11-17T08:35:08",
					eventState: "pending",
					id: "a9cfb505-7df2-473e-987f-0fa2a9c01349",
					metadataTimestamp: "2023-11-17T08:35:09",
					referenceId: "3121060460",
					referenceType: "order",
					ttl: 1731832508,
					type: "rebooking",
					uuid: "d9e1ab3a-491f-4041-88fc-8e10d2628540",
				},
			},
			payments: [
				{
					id: "2739411293",
					paymentHash: "4867095ea3d2d7c01919fc6e287c91146dc59d267cc2aa2f46",
					paypalAuthorizationId: "",
					amount: 357.0,
					amountOutstanding: 0,
					currency: "EUR",
					amountRefunded: 0,
					psp: "adyen",
					method: "adyenCC",
					pspReference: "MQXMLW4WRSXRGHG2",
					status: "paid",
					createdAt: "2022-10-03T08:43:32",
				},
			],
		},
		{
			id: "304796271",
			orderItems: [
				{
					id: "4767607944",
					orderId: "3047962711",
					fromId: "48021",
					toId: "49551",
					route: "TST - ABC",
					fullRouteName: "Testing Route Name",
					description:
						"2022-12-15 22:45:00: İstanbul Dudullu bus station - Altınoluk bus station",
					ticketId: "kamilkoc-3029689352",
					rideId: "155424781",
					passengerId: "123123",
					mandateSourceId: "1761",
					mandateSource: null,
					departAt: null,
					concession: null,
					passenger: {
						id: "18121212121",
						firstName: "Test",
						lastName: "John",
						birthDate: null,
						phone: "123-123-123",
						type: "adult",
						createdAt: "2022-12-13T18:41:13",
						updatedAt: "2022-12-13T18:41:13",
					},
					interconnectionId: null,
					type: "adult",
					priceType: null,
					currency: "EUR",
					currencyId: "201",
					productId: null,
					priceNet: 296.61,
					priceGross: 350.0,
					createdAt: "2022-12-13T18:41:36",
					updatedAt: "2022-12-15T23:05:53",
				},
				{
					id: "4767607953",
					orderId: "3047962711",
					fromId: "48021",
					toId: "49551",
					route: "TST - ABC",
					fullRouteName: null,
					description:
						"Premium Seat for 2022-12-15 22:45:00: İstanbul Dudullu bus station - Altınoluk bus station",
					ticketId: "kamilkoc-3029689353",
					rideId: "155424781",
					passengerId: null,
					mandateSourceId: "1761",
					mandateSource: null,
					departAt: null,
					concession: null,
					passenger: {
						phone: "222-33-33",
					},
					interconnectionId: null,
					type: "product",
					priceType: null,
					currency: "EUR",
					currencyId: "201",
					productId: null,
					priceNet: 5.93,
					priceGross: 7.0,
					createdAt: "2022-12-13T18:41:36",
					updatedAt: "2022-12-13T18:41:36",
				},
			],
			comments: null,
			sourceOrderId: "35361837123",
			resultOrderId: null,
			customerEmail: "test@gmail.com",
			status: "PAID",
			interconnectionId: null,
			reservationId: "6394513963",
			reference: "3047962710",
			vouchers: null,
			urlKey: null,
			user: null,
			userId: null,
			languageId: "138",
			language: { id: "138", name: "Turkish", code: "tr" },
			lineId: null,
			type: "web",
			shopId: null,
			shop: "flixbus_tr",
			downloadHash: "x06yu17hbmurfeb7lo58qgjv6veig8jtnaxrq8u71ujy8zinb2",
			mandateSourceId: "15",
			mandateSource: null,
			currency: "EUR",
			currencyId: "201",
			value: 357.0,
			discount: 0.0,
			credit: 0.0,
			donation: 0.0,
			returnedDonation: 0.0,
			grandTotal: 357.0,
			baseTotal: 18.1298,
			subtotalNet: 302.54,
			subtotalGross: 357.0,
			refund: 0.0,
			taxes: 54.46,
			interconnection: false,
			createdAt: "2022-12-13T18:41:36",
			updatedAt: "2022-12-13T18:41:36",
			deliveries: [],
			payments: null,
		},
		{
			id: "3047962711",
			orderItems: [
				{
					id: "4767607943",
					orderId: "3047962711",
					fromId: "48021",
					toId: "49551",
					route: null,
					fullRouteName: null,
					description:
						"2022-12-15 22:45:00: İstanbul Dudullu bus station - Altınoluk bus station",
					ticketId: "kamilkoc-3029689352",
					rideId: "155424781",
					interconnectionId: "INTERCONNECTION",
					passengerId: "123123",
					mandateSourceId: "1761",
					mandateSource: null,
					departAt: null,
					concession: null,
					passenger: {
						id: "18121212121",
						firstName: "Test",
						lastName: "Smith",
						birthDate: null,
						phone: "123-123-123",
						type: "adult",
						createdAt: "2022-12-13T18:41:13",
						updatedAt: "2022-12-13T18:41:13",
					},
					interconnectionId: null,
					type: "adult",
					priceType: null,
					currency: "EUR",
					currencyId: "201",
					productId: null,
					priceNet: 296.61,
					priceGross: 350.0,
					createdAt: "2022-12-13T18:41:36",
					updatedAt: "2022-12-15T23:05:53",
				},
				{
					id: "4767607953",
					orderId: "3047962711",
					fromId: "48021",
					toId: "49551",
					route: null,
					fullRouteName: null,
					description:
						"Premium Seat for 2022-12-15 22:45:00: İstanbul Dudullu bus station - Altınoluk bus station",
					ticketId: "kamilkoc-3029689353",
					rideId: "155424781",
					passengerId: null,
					mandateSourceId: "1761",
					mandateSource: null,
					departAt: null,
					concession: null,
					passenger: null,
					interconnectionId: null,
					type: "product",
					priceType: null,
					currency: "EUR",
					currencyId: "201",
					productId: null,
					priceNet: 5.93,
					priceGross: 7.0,
					createdAt: "2022-12-13T18:41:36",
					updatedAt: "2022-12-13T18:41:36",
				},
			],
			comments: null,
			sourceOrderId: null,
			resultOrderId: null,
			customerEmail: "test@gmail.com",
			status: "PAID",
			interconnectionId: null,
			reservationId: "6394513963",
			vouchers: null,
			urlKey: null,
			user: null,
			userId: null,
			languageId: "138",
			language: { id: "138", name: "Turkish", code: "tr" },
			lineId: null,
			type: "web",
			shopId: null,
			shop: "flixbus_tr",
			downloadHash: "x06yu17hbmurfeb7lo58qgjv6veig8jtnaxrq8u71ujy8zinb2",
			mandateSourceId: "15",
			mandateSource: null,
			currency: "EUR",
			currencyId: "201",
			value: 357.0,
			discount: 0.0,
			credit: 0.0,
			donation: 0.0,
			returnedDonation: 0.0,
			grandTotal: 357.0,
			baseTotal: 18.1298,
			subtotalNet: 302.54,
			subtotalGross: 357.0,
			refund: 0.0,
			taxes: 54.46,
			interconnection: false,
			createdAt: "2022-12-13T18:41:36",
			updatedAt: "2022-12-13T18:41:36",
			deliveries: null,
			payments: null,
		},
		{
			id: "3047962711",
			orderItems: [
				{
					id: "4767607943",
					orderId: "3047962711",
					fromId: "48021",
					toId: "49551",
					route: null,
					fullRouteName: null,
					description:
						"2022-12-15 22:45:00: İstanbul Dudullu bus station - Altınoluk bus station",
					ticketId: "kamilkoc-3029689352",
					rideId: "155424781",
					interconnectionId: "INTERCONNECTION",
					passengerId: "123123",
					mandateSourceId: "1761",
					mandateSource: null,
					departAt: "2022-12-13T18:41:13",
					concession: null,
					passenger: {
						id: "18121212121",
						firstName: "Test",
						lastName: "Smith",
						birthDate: null,
						phone: "123-123-123",
						type: "adult",
						createdAt: "2022-12-13T18:41:13",
						updatedAt: "2022-12-13T18:41:13",
					},
					interconnectionId: null,
					type: "adult",
					priceType: null,
					currency: "EUR",
					currencyId: "201",
					productId: null,
					priceNet: 296.61,
					priceGross: 350.0,
					createdAt: "2022-12-13T18:41:36",
					updatedAt: "2022-12-15T23:05:53",
				},
				{
					id: "4767607953",
					orderId: "3047962711",
					fromId: "48021",
					toId: "49551",
					route: null,
					fullRouteName: null,
					description:
						"Premium Seat for 2022-12-15 22:45:00: İstanbul Dudullu bus station - Altınoluk bus station",
					ticketId: "kamilkoc-3029689353",
					rideId: "155424781",
					passengerId: null,
					mandateSourceId: "1761",
					mandateSource: null,
					departAt: "2022-12-13T18:41:13",
					concession: null,
					passenger: null,
					interconnectionId: null,
					type: "product",
					priceType: null,
					currency: "EUR",
					currencyId: "201",
					productId: null,
					priceNet: 5.93,
					priceGross: 7.0,
					createdAt: "2022-12-13T18:41:36",
					updatedAt: "2022-12-13T18:41:36",
				},
			],
			comments: null,
			sourceOrderId: null,
			resultOrderId: null,
			customerEmail: "test@gmail.com",
			status: "PAID",
			interconnectionId: null,
			reservationId: "6394513963",
			vouchers: null,
			urlKey: null,
			user: null,
			userId: null,
			languageId: "138",
			language: { id: "138", name: "Turkish", code: "tr" },
			lineId: null,
			type: "web",
			shopId: null,
			shop: "flixbus_tr",
			downloadHash: "x06yu17hbmurfeb7lo58qgjv6veig8jtnaxrq8u71ujy8zinb2",
			mandateSourceId: "15",
			mandateSource: null,
			currency: "EUR",
			currencyId: "201",
			value: 357.0,
			discount: 0.0,
			credit: 0.0,
			donation: 0.0,
			returnedDonation: 0.0,
			grandTotal: 357.0,
			baseTotal: 18.1298,
			subtotalNet: 302.54,
			subtotalGross: 357.0,
			refund: 0.0,
			taxes: 54.46,
			interconnection: false,
			createdAt: "2022-12-13T18:41:36",
			updatedAt: "2022-12-13T18:41:36",
			deliveries: null,
			payments: null,
		},
		{
			id: "3047962711",
			orderItems: [
				{
					id: "4767607943",
					orderId: "3047962711",
					fromId: "48021",
					toId: "49551",
					route: null,
					fullRouteName: null,
					description:
						"2022-12-15 22:45:00: İstanbul Dudullu bus station - Altınoluk bus station",
					ticketId: "kamilkoc-3029689352",
					rideId: "155424781",
					passengerId: "123123",
					mandateSourceId: "1761",
					mandateSource: null,
					interconnectionId: "INTERCONNECTION",
					departAt: null,
					concession: null,
					passenger: {
						id: "18121212121",
						firstName: "Test",
						lastName: "Smith",
						birthDate: null,
						phone: "123-123-123",
						type: "adult",
						createdAt: "2022-12-13T18:41:13",
						updatedAt: "2022-12-13T18:41:13",
					},
					interconnectionId: "INTERCONNECTION",
					type: "adult",
					priceType: null,
					currency: "EUR",
					currencyId: "201",
					productId: null,
					priceNet: 296.61,
					priceGross: 350.0,
					createdAt: "2022-12-13T18:41:36",
					updatedAt: "2022-12-15T23:05:53",
				},
				{
					id: "4767607953",
					orderId: "3047962711",
					fromId: "48021",
					toId: "49551",
					route: null,
					fullRouteName: null,
					description:
						"Premium Seat for 2022-12-15 22:45:00: İstanbul Dudullu bus station - Altınoluk bus station",
					ticketId: "kamilkoc-3029689353",
					rideId: "155424781",
					passengerId: null,
					mandateSourceId: "1761",
					mandateSource: null,
					departAt: null,
					concession: null,
					passenger: null,
					interconnectionId: null,
					type: "product",
					priceType: null,
					currency: "EUR",
					currencyId: "201",
					productId: null,
					priceNet: 5.93,
					priceGross: 7.0,
					createdAt: "2022-12-13T18:41:36",
					updatedAt: "2022-12-13T18:41:36",
				},
			],
			comments: null,
			sourceOrderId: null,
			resultOrderId: null,
			customerEmail: "test@gmail.com",
			status: "PAID",
			interconnectionId: null,
			reservationId: "6394513963",
			vouchers: null,
			urlKey: null,
			user: null,
			userId: null,
			languageId: "138",
			language: { id: "138", name: "Turkish", code: "tr" },
			lineId: null,
			type: "web",
			shopId: null,
			shop: "flixbus_tr",
			downloadHash: "x06yu17hbmurfeb7lo58qgjv6veig8jtnaxrq8u71ujy8zinb2",
			mandateSourceId: "15",
			mandateSource: null,
			currency: "EUR",
			currencyId: "201",
			value: 357.0,
			discount: 0.0,
			credit: 0.0,
			donation: 0.0,
			returnedDonation: 0.0,
			grandTotal: 357.0,
			baseTotal: 18.1298,
			subtotalNet: 302.54,
			subtotalGross: 357.0,
			refund: 0.0,
			taxes: 54.46,
			interconnection: false,
			createdAt: "2022-12-13T18:41:36",
			updatedAt: "2022-12-13T18:41:36",
			deliveries: null,
			payments: null,
		},
		{
			id: "3047962711",
			orderItems: [
				{
					id: "4767607943",
					orderId: "3047962711",
					fromId: "48021",
					toId: "49551",
					route: null,
					fullRouteName: null,
					description:
						"2022-12-15 22:45:00: İstanbul Dudullu bus station - Altınoluk bus station",
					ticketId: "kamilkoc-3029689352",
					rideId: "155424781",
					passengerId: "123123",
					mandateSourceId: "1761",
					mandateSource: null,
					departAt: null,
					concession: null,
					passenger: {
						id: "18121212121",
						firstName: "John",
						lastName: "Smith",
						birthDate: null,
						phone: "123-123-123",
						type: "adult",
						createdAt: "2022-12-13T18:41:13",
						updatedAt: "2022-12-13T18:41:13",
					},
					interconnectionId: "INTERCONNECTION",
					type: "adult",
					priceType: null,
					currency: "EUR",
					currencyId: "201",
					productId: null,
					priceNet: 296.61,
					priceGross: 350.0,
					createdAt: "2022-12-13T18:41:36",
					updatedAt: "2022-12-15T23:05:53",
				},
				{
					id: "4767607953",
					orderId: "3047962711",
					fromId: "48021",
					toId: "49551",
					route: null,
					fullRouteName: null,
					description:
						"Premium Seat for 2022-12-15 22:45:00: İstanbul Dudullu bus station - Altınoluk bus station",
					ticketId: "kamilkoc-3029689353",
					rideId: "155424781",
					passengerId: null,
					mandateSourceId: "1761",
					mandateSource: null,
					departAt: null,
					concession: null,
					passenger: null,
					interconnectionId: null,
					type: "product",
					priceType: null,
					currency: "EUR",
					currencyId: "201",
					productId: null,
					priceNet: 5.93,
					priceGross: 7.0,
					createdAt: "2022-12-13T18:41:36",
					updatedAt: "2022-12-13T18:41:36",
				},
			],
			comments: null,
			sourceOrderId: null,
			resultOrderId: null,
			customerEmail: "test@gmail.com",
			status: "PAID",
			interconnectionId: null,
			reservationId: "6394513963",
			vouchers: null,
			urlKey: null,
			user: null,
			userId: null,
			languageId: "138",
			language: { id: "138", name: "Turkish", code: "tr" },
			lineId: null,
			type: "web",
			shopId: null,
			shop: "flixbus_tr",
			downloadHash: "x06yu17hbmurfeb7lo58qgjv6veig8jtnaxrq8u71ujy8zinb2",
			mandateSourceId: "15",
			mandateSource: null,
			currency: "EUR",
			currencyId: "201",
			value: 357.0,
			discount: 0.0,
			credit: 0.0,
			donation: 0.0,
			returnedDonation: 0.0,
			grandTotal: 357.0,
			baseTotal: 18.1298,
			subtotalNet: 302.54,
			subtotalGross: 357.0,
			refund: 0.0,
			taxes: 54.46,
			interconnection: false,
			createdAt: "2022-12-13T18:41:36",
			updatedAt: "2022-12-13T18:41:36",
			deliveries: null,
			payments: null,
		},
		{
			id: "3047962711",
			orderItems: [
				{
					id: "4767607943",
					orderId: "3047962711",
					fromId: "48021",
					toId: "49551",
					route: null,
					fullRouteName: null,
					description:
						"2022-12-15 22:45:00: İstanbul Dudullu bus station - Altınoluk bus station",
					ticketId: "kamilkoc-3029689352",
					rideId: "155424781",
					passengerId: "123123",
					mandateSourceId: "1761",
					mandateSource: null,
					departAt: null,
					concession: null,
					passenger: {
						id: "18121212121",
						firstName: "Sam",
						lastName: "Smith",
						birthDate: null,
						phone: "123-123-123",
						type: "adult",
						createdAt: "2022-12-13T18:41:13",
						updatedAt: "2022-12-13T18:41:13",
					},
					interconnectionId: "INTERCONNECTION",
					type: "adult",
					priceType: null,
					currency: "EUR",
					currencyId: "201",
					productId: null,
					priceNet: 296.61,
					priceGross: 350.0,
					createdAt: "2022-12-13T18:41:36",
					updatedAt: "2022-12-15T23:05:53",
				},
				{
					id: "4767607953",
					orderId: "3047962711",
					fromId: "48021",
					toId: "49551",
					route: null,
					fullRouteName: null,
					description:
						"Premium Seat for 2022-12-15 22:45:00: İstanbul Dudullu bus station - Altınoluk bus station",
					ticketId: "kamilkoc-3029689353",
					rideId: "155424781",
					passengerId: null,
					mandateSourceId: "1761",
					mandateSource: null,
					departAt: null,
					concession: null,
					passenger: null,
					interconnectionId: "DIRECT",
					type: "product",
					priceType: null,
					currency: "EUR",
					currencyId: "201",
					productId: null,
					priceNet: 5.93,
					priceGross: 7.0,
					createdAt: "2022-12-13T18:41:36",
					updatedAt: "2022-12-13T18:41:36",
				},
			],
			comments: null,
			sourceOrderId: null,
			resultOrderId: null,
			customerEmail: "test@gmail.com",
			status: "PAID",
			interconnectionId: null,
			reservationId: "6394513963",
			vouchers: null,
			urlKey: null,
			user: null,
			userId: null,
			languageId: "138",
			language: { id: "138", name: "Turkish", code: "tr" },
			lineId: null,
			type: "web",
			shopId: null,
			shop: "flixbus_tr",
			downloadHash: "x06yu17hbmurfeb7lo58qgjv6veig8jtnaxrq8u71ujy8zinb2",
			mandateSourceId: "15",
			mandateSource: null,
			currency: "EUR",
			currencyId: "201",
			value: 357.0,
			discount: 0.0,
			credit: 0.0,
			donation: 0.0,
			returnedDonation: 0.0,
			grandTotal: 357.0,
			baseTotal: 18.1298,
			subtotalNet: 302.54,
			subtotalGross: 357.0,
			refund: 0.0,
			taxes: 54.46,
			interconnection: false,
			createdAt: "2022-12-13T18:41:36",
			updatedAt: "2022-12-13T18:41:36",
			deliveries: null,
			payments: null,
		},
		{
			id: "3047962711",
			orderItems: [
				{
					id: "4767607943",
					orderId: "3047962711",
					fromId: "48021",
					toId: "49551",
					route: null,
					fullRouteName: null,
					description:
						"2022-12-15 22:45:00: İstanbul Dudullu bus station - Altınoluk bus station",
					ticketId: "kamilkoc-3029689352",
					rideId: "155424781",
					passengerId: "123123",
					mandateSourceId: "1761",
					mandateSource: null,
					departAt: null,
					concession: null,
					passenger: {
						id: "18121212121",
						firstName: "Jack",
						lastName: "Smith",
						birthDate: null,
						phone: "123-123-123",
						type: "adult",
						createdAt: "2022-12-13T18:41:13",
						updatedAt: "2022-12-13T18:41:13",
					},
					interconnectionId: null,
					type: "adult",
					priceType: null,
					currency: "EUR",
					currencyId: "201",
					productId: null,
					priceNet: 296.61,
					priceGross: 350.0,
					createdAt: "2022-12-13T18:41:36",
					updatedAt: "2022-12-15T23:05:53",
				},
				{
					id: "4767607953",
					orderId: "3047962711",
					fromId: "48021",
					toId: "49551",
					route: null,
					fullRouteName: null,
					description:
						"Premium Seat for 2022-12-15 22:45:00: İstanbul Dudullu bus station - Altınoluk bus station",
					ticketId: "kamilkoc-3029689353",
					rideId: "155424781",
					passengerId: null,
					mandateSourceId: "1761",
					mandateSource: null,
					departAt: null,
					concession: null,
					passenger: null,
					interconnectionId: null,
					type: "product",
					priceType: null,
					currency: "EUR",
					currencyId: "201",
					productId: null,
					priceNet: 5.93,
					priceGross: 7.0,
					createdAt: "2022-12-13T18:41:36",
					updatedAt: "2022-12-13T18:41:36",
				},
			],
			comments: null,
			sourceOrderId: null,
			resultOrderId: null,
			customerEmail: "test@gmail.com",
			status: "PAID",
			interconnectionId: null,
			reservationId: "6394513963",
			vouchers: null,
			urlKey: null,
			user: null,
			userId: null,
			languageId: "138",
			language: { id: "138", name: "Turkish", code: "tr" },
			lineId: null,
			type: "web",
			shopId: null,
			shop: "flixbus_tr",
			downloadHash: "x06yu17hbmurfeb7lo58qgjv6veig8jtnaxrq8u71ujy8zinb2",
			mandateSourceId: "15",
			mandateSource: null,
			currency: "EUR",
			currencyId: "201",
			value: 357.0,
			discount: 0.0,
			credit: 0.0,
			donation: 0.0,
			returnedDonation: 0.0,
			grandTotal: 357.0,
			baseTotal: 18.1298,
			subtotalNet: 302.54,
			subtotalGross: 357.0,
			refund: 0.0,
			taxes: 54.46,
			interconnection: false,
			createdAt: "2022-12-13T18:41:36",
			updatedAt: "2022-12-13T18:41:36",
			deliveries: null,
			payments: null,
		},
		{
			id: "3047962711",
			orderItems: [
				{
					id: "4767607943",
					orderId: "3047962711",
					fromId: "48021",
					toId: "49551",
					route: null,
					fullRouteName: null,
					description:
						"2022-12-15 22:45:00: İstanbul Dudullu bus station - Altınoluk bus station",
					ticketId: "kamilkoc-3029689352",
					rideId: "155424781",
					passengerId: "123123",
					mandateSourceId: "1761",
					mandateSource: null,
					departAt: null,
					concession: null,
					passenger: {
						id: "18121212121",
						firstName: "Test",
						lastName: "Smith",
						birthDate: null,
						phone: "123-123-123",
						type: "adult",
						createdAt: "2022-12-13T18:41:13",
						updatedAt: "2022-12-13T18:41:13",
					},
					interconnectionId: null,
					type: "adult",
					priceType: null,
					currency: "EUR",
					currencyId: "201",
					productId: null,
					priceNet: 296.61,
					priceGross: 350.0,
					createdAt: "2022-12-13T18:41:36",
					updatedAt: "2022-12-15T23:05:53",
				},
				{
					id: "4767607953",
					orderId: "3047962711",
					fromId: "48021",
					toId: "49551",
					route: null,
					fullRouteName: null,
					description:
						"Premium Seat for 2022-12-15 22:45:00: İstanbul Dudullu bus station - Altınoluk bus station",
					ticketId: "kamilkoc-3029689353",
					rideId: "155424781",
					passengerId: null,
					mandateSourceId: "1761",
					mandateSource: null,
					departAt: null,
					concession: null,
					passenger: null,
					interconnectionId: null,
					type: "product",
					priceType: null,
					currency: "EUR",
					currencyId: "201",
					productId: null,
					priceNet: 5.93,
					priceGross: 7.0,
					createdAt: "2022-12-13T18:41:36",
					updatedAt: "2022-12-13T18:41:36",
				},
			],
			comments: null,
			sourceOrderId: null,
			resultOrderId: null,
			customerEmail: "test@gmail.com",
			status: "PAID",
			interconnectionId: null,
			reservationId: "6394513963",
			vouchers: null,
			urlKey: null,
			user: null,
			userId: null,
			languageId: "138",
			language: { id: "138", name: "Turkish", code: "tr" },
			lineId: null,
			type: "web",
			shopId: null,
			shop: "flixbus_tr",
			downloadHash: "x06yu17hbmurfeb7lo58qgjv6veig8jtnaxrq8u71ujy8zinb2",
			mandateSourceId: "15",
			mandateSource: null,
			currency: "EUR",
			currencyId: "201",
			value: 357.0,
			discount: 0.0,
			credit: 0.0,
			donation: 0.0,
			returnedDonation: 0.0,
			grandTotal: 357.0,
			baseTotal: 18.1298,
			subtotalNet: 302.54,
			subtotalGross: 357.0,
			refund: 0.0,
			taxes: 54.46,
			interconnection: false,
			createdAt: "2022-12-13T18:41:36",
			updatedAt: "2022-12-13T18:41:36",
			deliveries: null,
			payments: null,
		},
		{
			id: "3047962711",
			orderItems: [
				{
					id: "4767607943",
					orderId: "3047962711",
					fromId: "48021",
					toId: "49551",
					route: null,
					fullRouteName: null,
					description:
						"2022-12-15 22:45:00: İstanbul Dudullu bus station - Altınoluk bus station",
					ticketId: "kamilkoc-3029689352",
					rideId: "155424781",
					passengerId: "123123",
					mandateSourceId: "1761",
					mandateSource: null,
					departAt: null,
					concession: null,
					passenger: {
						id: "18121212121",
						firstName: "Test",
						lastName: "Test",
						birthDate: null,
						phone: "123-123-123",
						type: "adult",
						createdAt: "2022-12-13T18:41:13",
						updatedAt: "2022-12-13T18:41:13",
					},
					interconnectionId: null,
					type: "adult",
					priceType: null,
					currency: "EUR",
					currencyId: "201",
					productId: null,
					priceNet: 296.61,
					priceGross: 350.0,
					createdAt: "2022-12-13T18:41:36",
					updatedAt: "2022-12-15T23:05:53",
				},
				{
					id: "4767607953",
					orderId: "3047962711",
					fromId: "48021",
					toId: "49551",
					route: null,
					fullRouteName: null,
					description:
						"Premium Seat for 2022-12-15 22:45:00: İstanbul Dudullu bus station - Altınoluk bus station",
					ticketId: "kamilkoc-3029689353",
					rideId: "155424781",
					passengerId: null,
					mandateSourceId: "1761",
					mandateSource: null,
					departAt: null,
					concession: null,
					passenger: null,
					interconnectionId: null,
					type: "product",
					priceType: null,
					currency: "EUR",
					currencyId: "201",
					productId: null,
					priceNet: 5.93,
					priceGross: 7.0,
					createdAt: "2022-12-13T18:41:36",
					updatedAt: "2022-12-13T18:41:36",
				},
			],
			comments: null,
			sourceOrderId: null,
			resultOrderId: null,
			customerEmail: "test@gmail.com",
			status: "PAID",
			interconnectionId: null,
			reservationId: "6394513963",
			vouchers: null,
			urlKey: null,
			user: null,
			userId: null,
			languageId: "138",
			language: { id: "138", name: "Turkish", code: "tr" },
			lineId: null,
			type: "web",
			shopId: null,
			shop: "flixbus_tr",
			downloadHash: "x06yu17hbmurfeb7lo58qgjv6veig8jtnaxrq8u71ujy8zinb2",
			mandateSourceId: "15",
			mandateSource: null,
			currency: "EUR",
			currencyId: "201",
			value: 357.0,
			discount: 0.0,
			credit: 0.0,
			donation: 0.0,
			returnedDonation: 0.0,
			grandTotal: 357.0,
			baseTotal: 18.1298,
			subtotalNet: 302.54,
			subtotalGross: 357.0,
			refund: 0.0,
			taxes: 54.46,
			interconnection: false,
			createdAt: "2022-12-13T18:41:36",
			updatedAt: "2022-12-13T18:41:36",
			deliveries: null,
			payments: null,
		},
	],
	pageable: {
		sort: { sorted: true, unsorted: false, empty: false },
		pageNumber: 0,
		pageSize: 10,
		offset: 0,
		paged: true,
		unpaged: false,
	},
	totalElements: 205,
	totalPages: 10,
	last: false,
	first: true,
	numberOfElements: 10,
	sort: { sorted: true, unsorted: false, empty: false },
	size: 10,
	number: 0,
	empty: false,
};
