// components
import { SectionHeader } from "../SectionHeader/SectionHeader";
import { DataTable } from "@flixbus/honeycomb-react";
import { PromoCodeTableRow } from "../PromoCodeTableRow";
// constants
import { PromoCodesHeaders } from "../../../../constants";
// styles
import "./PromoCodesTable.scss";

export const PromoCodesTable = ({ vouchers }) => {
	return vouchers && vouchers.length ? (
		<>
			<SectionHeader type="voucher" headerText="Vouchers" />
			<DataTable
				headers={PromoCodesHeaders}
				scrollable
				extraClasses="SectionTable"
				data-testid="promo-codes-table"
			>
				{vouchers.map((voucher, i) => (
					<PromoCodeTableRow key={i} voucher={voucher} />
				))}
			</DataTable>
		</>
	) : null;
};
