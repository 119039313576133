import React from "react";
import { Icon, IconCalendar, IconTime } from "@flixbus/honeycomb-icons-react";
import Moment from "moment";
import "./StationDateAndTime.scss";

// time: route.arrival or route.departure
export const StationDateAndTime = ({ time }) => {
	return (
		<div className="Timestamp">
			<Icon InlineIcon={IconCalendar} size={3} extraClasses="Timestamp__icon" />
			<span>{Moment.parseZone(time).format("DD-MM")}</span>
			<Icon
				InlineIcon={IconTime}
				size={3}
				extraClasses="Timestamp__icon Timestamp__time"
			/>
			<span>{Moment.parseZone(time).format("HH:mm")}</span>
		</div>
	);
};
