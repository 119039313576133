import { Grid, GridCol, Tag, Link, Box } from "@flixbus/honeycomb-react";
import { DateAndTime, Price } from "../../../../components";
import { OrderStatusOptions } from "../../../../constants";
import {
	Icon,
	IconCrossed,
	IconCheckmarkStrong,
} from "@flixbus/honeycomb-icons-react";
import "./OrderDetailsInfo.scss";
import { useEffect, useState } from "react";
import { anonymizePersonalData } from "../../../../utils/anonymizePersonalData";
// constants
import * as CONSTANTS from "../../../../constants";

export const OrderDetailsInfo = (props) => {
	const { order } = props;
	const [customerEmail, setCustomerEmail] = useState(order.customerEmail);
	const [isRebooked, setIsRebooked] = useState(false);

	useEffect(() => {
		if (order) {
			// anonymize the phone number if it is dev environment
			if (!+process.env.REACT_APP_IS_PRODUCTION) {
				// anonymize the email if it is dev environment
				let email = anonymizePersonalData(order.customerEmail, "email");
				setCustomerEmail(email || "N/A");
			} else {
				setCustomerEmail(order.customerEmail || "N/A");
			}
			// check if the order has rebooked status
			if (
				order.rebookingStatus &&
				order.rebookingStatus.toLowerCase() === CONSTANTS.rebookedStatus
			) {
				setIsRebooked(true);
			}
		}
	}, [props]);

	return (
		<Grid>
			{/* basic info */}
			<GridCol key="grid-btm-a" md={6} size={12} extraClasses="d-flex">
				<Box small extraClasses="OrderInfoTable" data-testId="order-info-table">
					<table>
						<tr>
							<th>Order ID</th>
							<td>
								{order.resultOrderId || order.sourceOrderId ? (
									<>
										<>
											{order.resultOrderId && (
												<p>
													<Link
														href={`/orders/${order.resultOrderId}`}
														aria-label="Result order"
														target="_blank"
													>
														Result Order #{order.resultOrderId}
													</Link>
												</p>
											)}
										</>
										<>
											{order.sourceOrderId && (
												<p>
													<Link
														href={`/orders/${order.sourceOrderId}`}
														aria-label="Source order"
														target="_blank"
													>
														Source Order #{order.sourceOrderId}
													</Link>
												</p>
											)}
										</>
									</>
								) : (
									order.id
								)}
							</td>
						</tr>

						{/* order reference to indicate the very first order of the chain
						it also shouldn't be shown for the very 1st order */}
						{order.reference && order.reference !== order.id ? (
							<tr>
								<th>Order Reference</th>
								<td>
									<Link
										href={`/orders/${order.reference}`}
										aria-label="Order Reference"
										title="Order Reference"
										target="_blank"
									>
										{order.reference}
									</Link>
								</td>
							</tr>
						) : null}

						<tr>
							<th>E-mail</th>
							<td>{customerEmail}</td>
						</tr>
						<tr>
							<th>Status</th>
							<td>
								<Tag
									appearance={
										OrderStatusOptions.find(
											(status) =>
												status.value.toLowerCase() ==
												order.status?.toLowerCase()
										)?.appearance
									}
								>
									{order.status}
								</Tag>
								{isRebooked ? (
									<>
										<Tag
											display="outlined"
											appearance="success"
											extraClasses="additionalStatus"
										>
											{" "}
											REBOOKED
										</Tag>
									</>
								) : null}
							</td>
						</tr>
						{/* don't need to show status comment bc it's visible in StatusHistory block */}
						{/* {order.orderHistory &&
						order.orderHistory.length &&
						order.orderHistory[0]?.note ? (
							<tr>
								<th>Status Note</th>
								<td>
									<p className="StatusNote">{order.orderHistory[0].note}</p>
								</td>
							</tr>
						) : null} */}
						<tr>
							<th>Interconnections</th>
							<td>
								{order.interconnection ? (
									<Icon
										InlineIcon={IconCheckmarkStrong}
										size={4}
										title="With interconnections"
									/>
								) : (
									<Icon
										InlineIcon={IconCrossed}
										size={4}
										title="Without interconnections"
									/>
								)}
							</td>
						</tr>
						<tr>
							<th>Promo Code</th>
							<td>
								{order.vouchers && order.vouchers.length ? (
									<Icon
										InlineIcon={IconCheckmarkStrong}
										size={4}
										title="With promo code"
									/>
								) : (
									<Icon
										InlineIcon={IconCrossed}
										size={4}
										title="Without promo code"
									/>
								)}
							</td>
						</tr>
					</table>
				</Box>
			</GridCol>

			{/* other details */}
			<GridCol key="grid-btm-b" md={3} size={6} extraClasses="d-flex">
				<Box small extraClasses="OrderInfoTable">
					<table>
						<tr>
							<th>Created at</th>
							<td>
								{/* orders from CC have orders in CET, new orders are in UTC */}
								<DateAndTime
									timeStamp={order.createdAt}
									timeZone={
										order.isCarbonCopy
											? CONSTANTS.TIMEZONES.CET
											: CONSTANTS.TIMEZONES.UTC
									}
									isDepartureType={false}
								/>
							</td>
						</tr>
						<tr>
							<th>User</th>
							<td>{order.user ? order.user : order.userId || "N/A"}</td>
						</tr>
						<tr>
							<th>Language</th>
							<td>{order.language?.name}</td>
						</tr>
						<tr>
							<th>Type</th>
							<td>{order.type}</td>
						</tr>
						<tr>
							<th>Shop</th>
							<td>{order.shop}</td>
						</tr>
					</table>
				</Box>
			</GridCol>

			{/* basic payment info */}
			<GridCol key="grid-btm-c" md={3} size={6} extraClasses="d-flex">
				<Box small extraClasses="OrderInfoTable">
					<table>
						<tr>
							<th>Value</th>
							<td>
								<Price value={order.value} currency={order.currency} />
							</td>
						</tr>
						<tr>
							<th>Discount</th>
							<td>
								<Price value={order.discount} currency={order.currency} />
							</td>
						</tr>
						<tr>
							<th>Credit</th>
							<td>
								<Price value={order.credit} currency={order.currency} />
							</td>
						</tr>
						<tr>
							<th>Donation</th>
							<td>
								<Price value={order.donation} currency={order.currency} />
							</td>
						</tr>
						<tr>
							<th>Grand Total</th>
							<td>
								<Price value={order.grandTotal} currency={order.currency} />
							</td>
						</tr>
						<tr>
							<th>Refund</th>
							<td>
								{/* TODO: hide refund until it's implemented in BE */}
								{/* <Price value={order.refund} currency={order.currency} /> */}
								<span title="Temporary not available">N/A</span>
							</td>
						</tr>
					</table>
				</Box>
			</GridCol>
		</Grid>
	);
};
