export const updateChecboxesStateAfterDelete = (
	filterName,
	i,
	setStateFn,
	filterValues
) => {
	setStateFn((prev) => [
		...prev.map((opt) =>
			opt.value === filterValues[filterName][i]
				? { ...opt, checked: false }
				: opt
		),
	]);
};

export const updateCheckboxesState = (setStateFn, filterName, filterValues) => {
	setStateFn((prev) => {
		return [
			...prev.map((opt) => {
				if (filterValues[filterName]?.includes(opt.value)) {
					return { ...opt, checked: true };
				} else {
					return { ...opt, checked: false };
				}
			}),
		];
	});
};

export const setInitialCheckboxesState = (stateName, setStateFn) => {
	setStateFn(
		stateName.map((o) => {
			o.onChange = () => {
				o.checked = !o.checked;
			};
			return o;
		})
	);
};
