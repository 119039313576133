import { Tag } from "@flixbus/honeycomb-react";
import ordersLogo from "../../assets/Rebooking.svg";
import ridesLogo from "../../assets/Search.svg";
import vouchersLogo from "../../assets/Voucher.svg";

const HomeFeatures = [
	{
		name: "Orders",
		description: (
			<ul>
				<li>search orders</li>
				<li>view order details</li>
				{/* <li>access RideViewer for rides related to an order</li> */}
			</ul>
		),
		path: "orders",
		imgUrl: ordersLogo,
	},
	{
		name: "Rides",
		description: (
			<>
				<ul>
					<li>Locate rides by date, line number, trip number and others</li>
					<li>
						View ride details and access RideViewer for more rides information
					</li>
				</ul>
			</>
		),
		path: "rides",
		imgUrl: ridesLogo,
	},
	{
		name: <span>Vouchers</span>,
		description: (
			<div>
				<Tag extraClasses="mb-3">not active yet</Tag>
				<ul>
					<li>filter vouchers</li>
					<li>create new voucher</li>
					<li>get voucher info </li>
				</ul>
			</div>
		),
		path: "vouchers/search",
		imgUrl: vouchersLogo,
	},
];

export default HomeFeatures;
