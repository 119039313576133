import { DataTable } from "@flixbus/honeycomb-react";
import { useState } from "react";
import { OrderItemsHeaders } from "../../../../constants";
import { CommentsPopup } from "../../../../components/CommentsPopup/CommentsPopup";
import { OrderItemTableRow } from "../../components";
// import styles
import "./OrderItemsTable.scss";
import { SectionHeader } from "../SectionHeader/SectionHeader";

export const OrderItemsTable = ({ order, onCommentsUpdate = () => {} }) => {
	const [commentsPopupActive, setCommentsPopupActive] = useState(false);
	const [selectedItem, setSelectedItem] = useState({});
	const [commentsViewFormat, setCommentsViewFormat] = useState(false);

	const handleCommentPopupClick = (selectedItem, isViewFormat) => {
		setSelectedItem(selectedItem);
		setCommentsPopupActive(true);
		setCommentsViewFormat(isViewFormat);
	};

	const popupCloseHandle = (isCommentUpdated = false) => {
		setCommentsPopupActive(false);
		if (isCommentUpdated) {
			onCommentsUpdate();
		}
	};

	return order?.orderItems && order?.orderItems.length ? (
		<>
			<SectionHeader type="orders" headerText="Order Items" />
			<div data-testid="order-items-table">
				<DataTable headers={OrderItemsHeaders} scrollable>
					{order.orderItems.map((orderItem, index) => (
						<OrderItemTableRow
							key={index}
							orderItem={orderItem}
							onCommentsPopupClick={handleCommentPopupClick}
						/>
					))}
				</DataTable>
				{commentsPopupActive && (
					<CommentsPopup
						isViewHistory={commentsViewFormat}
						onClose={popupCloseHandle}
						selectedItem={selectedItem}
					/>
				)}
			</div>
		</>
	) : null;
};
