/* A component that can cut too long values to about 5 symbols, adds dots at the end and provide a button to copy full value */
import { Icon, IconCopy } from "@flixbus/honeycomb-icons-react";
import {
	Button,
	Notification,
	NotificationContainer,
} from "@flixbus/honeycomb-react";
import { useState } from "react";
import "./ValueWithCoping.scss";

export const ValueWithCoping = ({ value, dispayFullValue = false }) => {
	const [showNotification, setShowNotification] = useState(false);

	if (!value) {
		return null;
	}

	const lastIndex = value.length > 5 ? 5 : value.length;
	const shortValue = value.slice(0, lastIndex).concat("...");

	const handleCopyClick = () => {
		navigator.clipboard.writeText(value);
		setShowNotification(true);
	};

	return (
		<>
			<div className="ShortValue">
				<div className="ValueWithCopy">
					<span title={value}>{dispayFullValue ? value : shortValue}</span>
					<Button
						appearance="link"
						small
						aria-label="Copy value"
						title="Copy value"
						onClick={handleCopyClick}
					>
						<Icon InlineIcon={IconCopy} aria-hidden="true" />
					</Button>
				</div>
			</div>
			{/* Notifications */}
			{showNotification && (
				<NotificationContainer>
					<Notification
						toast
						appearance="success"
						dismissCallback={() => setShowNotification(false)}
						closeProps={{
							"aria-label": "Close",
							onClick: () => setShowNotification(false),
						}}
					>
						Value "{value}" is copied to clipboard!
					</Notification>
				</NotificationContainer>
			)}
		</>
	);
};
