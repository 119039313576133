import Moment from "moment";

// accepts route objects with arrival and departure properties
// returns exact time in hours and minutes
export const calculateTransferTime = (fromRoute, toRoute) => {
    if (!fromRoute || !toRoute) return null;
    const arrival = Moment(fromRoute.arrival);
    const departure = Moment(toRoute.departure);
    try {
        let transferTimeHours = departure.diff(arrival, "hours");
        let transferTimeMinutes = departure.diff(arrival, "minutes") % 60;
        return `${transferTimeHours} h ${transferTimeMinutes} mins`;
    } catch (error) {
        console.error("Error calculating transfer time", error);
        return null;
    }
};

// accepts two dates in string format and returns the difference in hours
export const calculateTotalDuration = (departure, arrival) => {
    if (!departure || !arrival) return null;
    let duration;

    try {
        let durationHours = Moment(arrival).diff(Moment(departure), "hours");
        let durationMinutes = Moment(arrival).diff(Moment(departure), "minutes") % 60;
        if (durationMinutes < 10) {
            durationMinutes = `0${durationMinutes}`;
        }
        let hourLable = durationHours <= 1 ? "h" : "hrs";
        duration = `${durationHours} : ${durationMinutes} ${hourLable}`;
        return duration;

    } catch (error) {
        console.error("Error calculating total duration", error);
        return null;
    }
};