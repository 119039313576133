import { useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useErrorStatus } from "../../utils/hooks";
import Moment from "moment";
// Honeycomb components
import {
	PageContainer,
	Heading,
	Spinner,
	Notification,
	NotificationContainer,
} from "@flixbus/honeycomb-react";
// Components
import { OrdersTable } from "./components/OrdersTable";
import { NoResultsMessage, Pagination } from "../../components";
import { OrdersSearch } from "./components";
// Constants
import {
	InitialOrderFormValues,
	DefaultPageSize,
	OrdersMockup,
} from "../../constants/index";
import * as Constants from "../../constants";
// API
import { getFrontendSources, getLanguages, getCurrencies } from "../../api";
import { getOrders } from "./Orders.service";
import { loginRequest } from "../../authConfig";
// Utils
import {
	clearEmptyFields,
	isLocalhost,
	trimNameSpaces,
	formatDateSearchCondition,
	checkedArrayValue,
	authenticate,
	addUserData,
} from "../../utils";

const Orders = () => {
	const { instance, accounts } = useMsal();
	const request = {
		...loginRequest,
		account: accounts[0],
	};
	const isAuthenticated = useIsAuthenticated();
	const [token, setToken] = useState(null);
	const [orders, setOrders] = useState(null);
	const [totalOrders, setTotalOrders] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [itemsOnPage, setItemsOnPage] = useState(Constants.DefaultPageSize);
	const [selectedPage, setSelectedPage] = useState(1);
	const [pagesAmount, setPagesAmount] = useState(1);
	const [filterValues, setFilterValues] = useState(InitialOrderFormValues);
	const [alertMessage, setAllertMessage] = useState("");
	const [showNotification, setShowNotification] = useState(false);
	const [frontendSources, setFrontendSources] = useState([]);
	const [languageList, setLanguageList] = useState([]);
	const [currencyList, setCurrencyList] = useState([]);
	const [errorStatus, setErrorStatus] = useState(null);

	useErrorStatus(errorStatus);

	let paginationComponent = (
		<Pagination
			pagesAmount={pagesAmount}
			currentPage={selectedPage}
			onPageChange={(page) => changePage(page)}
		/>
	);

	const showError = (
		message = "Something went wrong, please try again.",
		status = null
	) => {
		setAllertMessage(message);
		setShowNotification(true);
		setIsLoading(false);
		setErrorStatus(status);
	};

	const searchOrders = (
		ordersObj = InitialOrderFormValues,
		accessToken = token,
		pageNumber = 1
	) => {
		if (ordersObj) {
			let body = { ...ordersObj };
			// lineTitle is not needed
			body.lineTitle = "";
			// remove spaces from names
			body = trimNameSpaces(body);
			// format date search parameter for correct API format
			body.dateSearchCondition = formatDateSearchCondition(
				Moment(body.dateSearchCondition, Constants.DateFullYear).format(
					Constants.DateSearchFormat
				)
			);
			// if there is no selected date
			if (!body.dateSearchCondition) {
				setFilterValues((prev) => {
					return {
						...prev,
						dateSearchCondition: null,
					};
				});
			}
			body = addUserData(body, accounts[0]);
			body = clearEmptyFields(body);
			setIsLoading(true);
			getOrders(accessToken, body, pageNumber)
				.then((response) => {
					if (response.status == 200) {
						const itemsLength = response.data.content.length;

						setTotalOrders(response.data.totalElements);
						setOrders(response.data.content);
						setPagesAmount(response.data.totalPages || 1);
						setItemsOnPage(
							itemsOnPage > itemsLength
								? itemsLength
								: Constants.DefaultPageSize
						);
						setIsLoading(false);
					} else {
						setOrders([]);
						let errorMessage = `${
							response.message || Constants.DefaultErrorMessage
						}`;
						showError(errorMessage, response.response?.status);
					}
				})
				.catch((e) => {
					console.error(e);
					showError(e);
				});
		}
	};

	// initial rendering
	useEffect(() => {
		(async () => {
			if (isAuthenticated) {
				await instance
					.acquireTokenSilent(request)
					.then((response) => {
						if (response) {
							setToken(response.idToken);
						} else {
							showError("Please try to login again");
						}
					})
					.catch((e) => {
						console.error(e);
						showError(e.errorMessage);
					});
			} else {
				if (isLocalhost()) {
					// for local env
					console.log("Not authenticated on local host");
					setTotalOrders(OrdersMockup.totalElements);
					setOrders(OrdersMockup.content);
					setPagesAmount(OrdersMockup.totalPages || 1);
				} else {
					authenticate(instance);
				}
				setIsLoading(false);
			}
		})();
	}, [accounts, instance, isAuthenticated]);

	// as soon as token is aquired make initial API calls
	useEffect(() => {
		if (token) {
			searchOrders(InitialOrderFormValues, token);
			(async () => {
				// get list of languages, currencies and frontend sources
				try {
					let [feSources, languages, currencies] = await Promise.all([
						getFrontendSources(token),
						getLanguages(token),
						getCurrencies(token),
					]);
					setFrontendSources(checkedArrayValue(feSources));
					setLanguageList(checkedArrayValue(languages));
					setCurrencyList(checkedArrayValue(currencies));
				} catch (e) {
					console.log(e);
				}
			})();
		}
	}, [token]);

	// when click on pagination - make request to get new orders list
	const changePage = (nextPageIndex) => {
		setSelectedPage(nextPageIndex);
		searchOrders(filterValues, token, nextPageIndex);
	};

	return (
		<>
			<OrdersSearch
				frontendSources={frontendSources}
				currencies={currencyList}
				languages={languageList}
				onSubmit={(e) => {
					changePage(1);
					setFilterValues(e);
				}}
				onChange={(e) => {
					setFilterValues(e);
				}}
				onError={(message, status) => showError(message, status)}
			/>
			<PageContainer>
				{/* show preloader while data is fetching for orders */}
				{isLoading ? (
					<div
						className="d-flex justify-content-center py-4"
						aria-live="polite"
						aria-busy={isLoading}
					>
						<Spinner />
					</div>
				) : orders && orders.length ? (
					<>
						<Heading size={4} Elem="h2">
							Orders Found: {totalOrders}, Items per page: {DefaultPageSize}
						</Heading>
						<OrdersTable orders={orders} pagination={paginationComponent} />
					</>
				) : (
					<NoResultsMessage />
				)}
			</PageContainer>

			{/* Notifications */}
			{showNotification && (
				<NotificationContainer>
					<Notification
						toast
						appearance="danger"
						dismissCallback={() => setShowNotification(false)}
						closeProps={{
							"aria-label": "Close",
							onClick: () => setShowNotification(false),
						}}
					>
						{alertMessage}
					</Notification>
				</NotificationContainer>
			)}
		</>
	);
};

export default Orders;
