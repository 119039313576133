import { EmailDeliveryStatuses } from "../constants";

export const getTagAppearanceByStatus = (status) => {
    let appearance = undefined;
    if(status) {
        // loop through all statuses
        EmailDeliveryStatuses.forEach((EmailStatus) => {
            // if status matches
            if (EmailStatus.value === status.toUpperCase()) {
                // return appearance
                appearance = EmailStatus.appearance
            }
        })
    };
    return appearance;
};
