// input where user can enter order id and go to order details
import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { FormRow, Input, Button } from "@flixbus/honeycomb-react";
import { authenticate, isValidOrderId, isLocalhost } from "../../../../utils";

export const QuickOrderSearch = () => {
	const [orderId, setOrderId] = useState("");
	const [isValidIdValue, setIsValidIdValue] = useState(null);
	const navigate = useNavigate();
	const isAuthenticated = useIsAuthenticated();
	const { instance } = useMsal();

	const handleSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (isAuthenticated || isLocalhost()) {
			navigate(`orders/${orderId}`);
		} else {
			authenticate(instance);
		}
	};

	const onOrderIdChange = (e, setOrderId) => {
		// update value and delete all non-digit characters
		e.target.value = e.target.value.replace(/\D/g, "");
		setOrderId(e.target.value);
		setIsValidIdValue(isValidOrderId(e.target.value));
	};

	return (
		<form
			data-testid="quickOrderSearchForm"
			onSubmit={handleSubmit}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}
		>
			<FormRow>
				<Input
					data-testid="quickSearchInput"
					placeholder="Enter Booking Nr."
					type="string"
					extraClasses="OrderIdInput"
					valid={isValidIdValue}
					onChange={(e) => onOrderIdChange(e, setOrderId)}
				/>
				<Button
					data-testid="quickSearchButton"
					appearance="primary"
					display="block"
					type="submit"
					onClick={handleSubmit}
					// if value is empty - btn should be still available to click
					disabled={isValidIdValue === false}
				>
					Go to Order Details
				</Button>
			</FormRow>
		</form>
	);
};
