import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, GridCol, PageContainer } from "@flixbus/honeycomb-react";
import "./UnderConstruction.scss";

const UnderConstruction = () => {
	const navigate = useNavigate();

	const handleButtonClick = () => {
		navigate("/");
	};

	return (
		<PageContainer>
			<Grid align="center">
				<GridCol key="grid-btm-a" size={12}>
					<div className="UnderConstructionContainer">
						<h1>Under Construction</h1>
						<p>The page you are looking for is still under construction.</p>
						<br />
						<flix-illustration name="maintenance"></flix-illustration>
						<br />
						<Button appearance="primary" onClick={handleButtonClick}>
							Return to Home Page
						</Button>
					</div>
				</GridCol>
			</Grid>
		</PageContainer>
	);
};

export default UnderConstruction;
