/* The component displays passenger phone number or passenger full name inside a table cell.
It accepts either string (for phones) or array of strings (for names) */

export const PassengerCell = ({ passengerProp }) => {
	return passengerProp && passengerProp.length ? (
		<>
			<span className="LongLine">
				{Array.isArray(passengerProp)
					? passengerProp.map((prop, i) => {
							return <span key={`${prop}_${i}`}>{prop} </span>;
					  })
					: passengerProp}
			</span>
			<br />
		</>
	) : (
		<>
			<span>-</span>
			<br />
		</>
	);
};
